<template>
	<div>
		<table class="table table-bordered" v-if="question.questions.length > 0">
			<thead>
				<tr>
					<th></th>
					<input-contenteditable class="ltr-content" v-model="option.text" v-for="(option, oi) in question.options" :key="'o1-' + index + '-' + oi" :id="'o1-' + index + '-' + oi" _is="th" :style="{ width: 80 / question.options.length + '%' }" />
					<template v-for="(option, oi) in question.options">
						<b-popover v-if="oi > 1" :target="'o1-' + index + '-' + oi" triggers="focus" placement="top" :key="'o2-' + index + '-' + oi">
							<a href="" class="px-2 d-flex answer-trash" v-on:click.prevent="deleteOption(oi)">
								<i class="my-auto mx-auto fas fa-trash-alt"></i>
							</a>
						</b-popover>
					</template>
					<th v-if="question.options.length < 8">
						<b-button @click="addOption" variant="outline-primary" size="sm" class="mx-auto">+</b-button>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(q, qi) in question.questions" :key="'q-' + index + '-' + qi">
					<input-contenteditable class="ltr-content" :id="'q-' + index + '-' + qi" v-model="q.text" _is="td" tabindex="-1" />
					<b-popover v-if="qi > 0" :target="'q-' + index + '-' + qi" triggers="focus" placement="left">
						<a href="" class="px-2 d-flex answer-trash" v-on:click.prevent="deleteQuestion(qi)">
							<i class="my-auto mx-auto fas fa-trash-alt"></i>
						</a>
					</b-popover>
					<td v-for="(option, oii) in question.options" :key="'oq1-' + index + '-' + oii" style="vertical-align: middle; text-align: center;">
						<input type="radio" disabled />
					</td>
					<td v-if="question.options.length < 8"></td>
				</tr>
				<tr>
					<td>
						<b-button @click="addQuestion" variant="outline-primary" size="sm" class="mx-auto">+</b-button>
					</td>
					<td v-for="(option, oii) in question.options" :key="'oq2-' + index + '-' + oii"></td>
					<td v-if="question.options.length < 8"></td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import Option from '@/data/survey/type/Option';
import MatrixOption from "@/data/survey/type/MatrixOption";
import TranslationMatrixOption from "@/data/survey/translation/MatrixOption";
import TranslationQuestion from "@/data/survey/translation/Question";

export default {
	name: 'SurveyMatrix',
	props: {
		question: Object,
		index: Number,
	},
	data() {
		return {
			test: [],
		};
	},
	methods: {
		addOption: function() {
			let matrixOption = new MatrixOption();
			this.question.translation.forEach(translation => {
				let translationMatrixOption = new TranslationMatrixOption();
				translationMatrixOption.code = translation.code;
				matrixOption.translation.push(translationMatrixOption);
			});
			this.question.options.push(matrixOption);
		},
		deleteOption: function(index) {
			this.question.options.splice(index, 1);
		},
		addQuestion: function() {
			let matrixQuestion = new Option(uuidv4());
			this.question.translation.forEach(translation => {
				let translationQuestion = new TranslationQuestion();
				translationQuestion.code = translation.code;
				matrixQuestion.translation.push(translationQuestion);
			});
			this.question.questions.push(matrixQuestion);
		},
		deleteQuestion: function(index) {
			this.question.questions.splice(index, 1);
		},
		changeRegression: function(val) {
			this.question.questions.forEach(q => {
				q.is_regression = val;
			});
		},
	},
};
</script>

<style scoped>
table.table th,
table.table td {
	overflow-wrap: anywhere;
	vertical-align: baseline;
}
table.table th:first-of-type {
	width: 20%;
}
a.answer-trash > i {
	font-size: 1.2rem;
	color: #2f323e;
}
.ltr-content {
	direction: ltr;
	white-space: pre-wrap;
	white-space: -moz-pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word;
}
</style>
