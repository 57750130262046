import General from "@/data/survey/type/General";

class Text extends General {
	constructor(id) {
		super(id);
		this.type = 'text';
		this.option_0 = null;
		this.is_required = false;
	}
}

export default Text;
