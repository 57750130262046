import TopicConfiguration from "@/data/survey/TopicConfiguration";

class Topic {
	constructor(id) {
		this.id = id;
		this.name = '';
		this.title = '';
		this.description = '';
		this.questions = [];
		this.translation = [];
		this.configuration = new TopicConfiguration();
	}
}

export default Topic;
