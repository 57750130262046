class Answer {
	constructor(id) {
		this.id = id;
		this.text = '';
		this.follow = null;
		this.translation = [];
		this.points = 0;
	}
}

export default Answer;
