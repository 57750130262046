<template>
	<div>
		<div class="row">
			<div class="card card-custom card-stretch gutter-b col-12" :id="topic.id">
				<div class="topic-index d-flex">
					<span class="mx-auto my-auto">{{ index + 1 }}</span>
				</div>
				<div class="topic-type d-flex px-2">
					<span class="mx-auto my-auto">{{ $t('VIEWS.Survey.Create.Topic.topic') }}</span>
				</div>
				<div class="topic-tools d-flex">
					<a href="" class="px-2 d-flex" v-on:click.prevent="$emit('moveUp', topic)" v-if="index > 0">
						<i class="fas fa-angle-up" style="vertical-align: middle; font-size: 2rem; text-align: center;"></i>
					</a>
					<a href="" class="px-2 d-flex" v-on:click.prevent="$emit('moveDown', topic)" v-if="index < amount">
						<i class="fas fa-angle-down" style="vertical-align: middle; font-size: 2rem; text-align: center;"></i>
					</a>
					<a href="" class="px-2 d-flex" v-on:click.prevent="$emit('copyTopic', topic)">
						<i class="my-auto mx-auto fas fa-copy"></i>
					</a>
					<a href="" class="px-2 d-flex" v-on:click.prevent="$emit('deleteTopic', topic)">
						<i class="my-auto mx-auto fas fa-trash-alt"></i>
					</a>
				</div>
				<div class="card-body py-3 mt-10">
					<div class="row">
						<div class="col-12 col-md-6">
							<div class="form-group">
								<label>{{ $t('VIEWS.Survey.Create.General.name') }}</label>
								<b-form-input v-model="topic.name" name="name" type="text" v-on:focusout="refresh"></b-form-input>
							</div>
						</div>
						<div class="col-12 col-md-6">
							<div class="form-group">
								<label>{{ $t('VIEWS.Survey.Create.General.title') }}</label>
								<b-form-input v-model="topic.title" ref="title" name="name" type="text" v-on:focusout="refresh" :id="'topic-' + index + '-title'"></b-form-input>
								<b-popover :target="'topic-' + index + '-title'" triggers="focus" placement="top" v-if="personalised === true">
									<h6>{{ $t('VIEWS.Survey.Create.Placeholder.title') }}</h6>
									<template v-for="(name, value) in personalPlaceholder">
										<b-button v-if="value !== 'that'" variant="primary" class="m-2" v-on:click.prevent="personalPlaceholder.insertPlaceholder( name, 'topic', 'title')" :key="'topic-' + index + '-title-' + value">{{ $t('VIEWS.Survey.Create.Placeholder.' + value) }}</b-button>
									</template>
								</b-popover>
							</div>
						</div>
						<div class="col-12">
							<div class="form-group">
								<label>{{ $t('VIEWS.Survey.Create.General.description') }}</label>
								<textarea class="form-control" rows="5" ref="description" name="description" v-model="topic.description" v-on:focusout="refresh" :id="'topic-' + index + '-description'"></textarea>
								<b-popover :target="'topic-' + index + '-description'" triggers="focus" placement="top" v-if="personalised === true">
									<h6>{{ $t('VIEWS.Survey.Create.Placeholder.title') }}</h6>
									<template v-for="(name, value) in personalPlaceholder">
										<b-button v-if="value !== 'that'" variant="primary" class="m-2" v-on:click.prevent="personalPlaceholder.insertPlaceholder( name, 'topic', 'description')" :key="'topic-' + index + '-description-' + value">{{ $t('VIEWS.Survey.Create.Placeholder.' + value) }}</b-button>
									</template>
								</b-popover>
							</div>
						</div>
					</div>

					<div class="row" v-if="topic.questions.length > 0">
						<QuestionContainer
							v-for="(question, qi) in topic.questions"
							:key="'q' + topic.id + '-' + qi"
							:question="question"
							:index="qi"
							:type="getType(question)"
							:amount="topic.questions.length - 1"
							:personalised="personalised"
							@moveUp="moveUp"
							@moveDown="moveDown"
							@copyQuestion="copy"
							@deleteQuestion="deleteQuestion"
							@refresh="refresh"
						>
							<SurveyOption v-if="checkType(question, 'option')" :followOptions="followOptions" :question="question" :index="qi" @setRegressionTarget="$emit('setRegressionTarget', $event)"></SurveyOption>
							<SurveyText v-if="checkType(question, 'text')" :followOptions="followOptions" :question="question" :index="qi"></SurveyText>
							<SurveyContact v-if="checkType(question, 'contact')" :followOptions="followOptions" :question="question" :index="qi"></SurveyContact>
							<SurveyNps v-if="checkType(question, 'nps')" :followOptions="followOptions" :question="question" :index="qi" @setRegressionTarget="$emit('setRegressionTarget', $event)"></SurveyNps>
							<SurveyMatrix v-if="checkType(question, 'matrix')" :question="question" :index="qi"></SurveyMatrix>
						</QuestionContainer>
					</div>
				</div>
				<div class="card-footer new-question">
					<div class="d-inline-block w-100">
						<label class="mt-3">{{ $t('VIEWS.Survey.Create.Topic.addQuestion') }}</label>
						<b-button-group class="float-right">
							<b-button variant="outline-primary" v-on:click="addQuestion('text')">{{ $t('VIEWS.Survey.Create.Topic.text') }}</b-button>
							<b-button variant="outline-primary" v-on:click="addQuestion('option')">{{ $t('VIEWS.Survey.Create.Topic.option') }}</b-button>
							<b-button variant="outline-primary" v-on:click="addQuestion('matrix')">{{ $t('VIEWS.Survey.Create.Topic.matrix') }}</b-button>
							<b-button variant="outline-primary" v-on:click="addQuestion('nps')">{{ $t('VIEWS.Survey.Create.Topic.recommendation') }}</b-button>
							<b-button variant="outline-primary" v-on:click="addQuestion('contact')">{{ $t('VIEWS.Survey.Create.Topic.contact') }}</b-button>
						</b-button-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SurveyOption from '@/view/component/survey/SurveyOption';
import { v4 as uuidv4 } from 'uuid';
import Option from '@/data/survey/type/Option.js';
import Answer from '@/data/survey/type/Answer';
import Text from '@/data/survey/type/Text.js';
import Matrix from '@/data/survey/type/Matrix.js';
import QuestionContainer from '@/view/component/survey/misc/QuestionContainer';
import SurveyText from '@/view/component/survey/SurveyText';
import SurveyNps from '@/view/component/survey/SurveyNps';
import Nps from '@/data/survey/type/Nps';
import SurveyMatrix from '@/view/component/survey/SurveyMatrix';
import SurveyContact from '@/view/component/survey/SurveyContact';
import { klona } from 'klona/lite';
// import { required } from 'vuelidate/lib/validators';
// import { validationMixin } from 'vuelidate';
import MatrixOption from '@/data/survey/type/MatrixOption';
import TranslationQuestion from '@/data/survey/translation/Question';
import TranslationMatrixOption from '@/data/survey/translation/MatrixOption';
import TranslationAnswer from '@/data/survey/translation/Answer';
import {Contact, ContactField} from '@/data/survey/type/Contact';
import Placeholder from "@/data/survey/Placeholder";

export default {
	name: 'SurveyTopic',
	components: { SurveyMatrix, SurveyNps, SurveyText, QuestionContainer, SurveyOption, SurveyContact },
	props: {
		topic: Object,
		index: Number,
		amount: Number,
		followOptions: Array,
		personalised: Boolean,
	},
	// mixins: [validationMixin],
	// validations: {
	// 	topic: {
	// 		name: {
	// 			required,
	// 		},
	// 	},
	// },
	computed: {
		personalPlaceholder: function() {
			return new Placeholder(this);
		}
	},
	methods: {
		addQuestion: function(type) {
			let q = null;
			if (type === 'option') {
				q = new Option(uuidv4());

				let answer1 = new Answer(uuidv4());
				let answer2 = new Answer(uuidv4());
				this.topic.translation.forEach(translation => {
					let translationAnswer1 = new TranslationAnswer();
					translationAnswer1.code = translation.code;
					answer1.translation.push(translationAnswer1);

					let translationAnswer2 = new TranslationAnswer();
					translationAnswer2.code = translation.code;
					answer2.translation.push(translationAnswer2);
				});
				q.answers = [answer1, answer2];
			} else if (type === 'text') {
				q = new Text(uuidv4());
			} else if (type === 'information') {
				q = new Text(uuidv4());
				q.type = 'information';
			} else if (type === 'contact') {
				q = new Contact(uuidv4());
				q.option_2 = [new ContactField(uuidv4(), '', 'text', false)];

				this.topic.translation.forEach(translation => {
					let translationField = new TranslationAnswer();
					translationField.code = translation.code;
					q.option_2[0].translation.push(translationField);
				});
			} else if (type === 'nps') {
				q = new Nps(uuidv4());
			} else if (type === 'matrix') {
				q = new Matrix(uuidv4());
				let mq = new Option(uuidv4());
				this.topic.translation.forEach(translation => {
					let translationMatrixQuestion = new TranslationQuestion();
					translationMatrixQuestion.code = translation.code;
					mq.translation.push(translationMatrixQuestion);
				});
				q.questions = [mq];

				let mo1 = new MatrixOption();
				let mo2 = new MatrixOption();
				this.topic.translation.forEach(translation => {
					let translationMatrixOption1 = new TranslationMatrixOption();
					let translationMatrixOption2 = new TranslationMatrixOption();
					translationMatrixOption1.code = translation.code;
					translationMatrixOption2.code = translation.code;
					mo1.translation.push(translationMatrixOption1);
					mo2.translation.push(translationMatrixOption2);
				});
				q.options = [mo1, mo2];
			}

			if (q != null) {
				this.topic.translation.forEach(translation => {
					let questionTranslation = new TranslationQuestion();
					questionTranslation.code = translation.code;
					q.translation.push(questionTranslation);
				});

				this.topic.questions.push(q);
				this.$emit('refreshOptions');
			}
		},
		checkType: function(q, type) {
			if (type === 'option' && q instanceof Option) {
				return true;
			} else if (type === 'text' && q instanceof Text) {
				return true;
			} else if (type === 'contact' && q instanceof Contact) {
				return true;
			} else if (type === 'nps' && q instanceof Nps) {
				return true;
			} else if (type === 'matrix' && q instanceof Matrix) {
				return true;
			}

			return false;
		},
		getType: function(q) {
			if (q instanceof Option) {
				return q.type;
			} else if (q instanceof Text) {
				return q.type;
			} else if (q instanceof Contact) {
				return 'contact';
			} else if (q instanceof Nps) {
				return 'nps';
			} else if (q instanceof Matrix) {
				return 'matrix';
			} else {
				return false;
			}
		},
		refresh: function() {
			this.$emit('refreshOptions');
		},
		copy: function(q) {
			let copied = klona(q);
			copied.id = uuidv4();
			this.topic.questions.push(copied);
			this.refresh();
		},
		deleteQuestion: function(q) {
			this.topic.questions = this.topic.questions.filter(function(value) {
				return value.id !== q.id;
			});
			this.refresh();
		},
		moveUp: function(q) {
			let index = this.topic.questions.indexOf(q);
			let length = this.topic.questions.length;

			if (index - 1 >= length) {
				let k = index - 1 - length + 1;
				while (k--) {
					this.topic.questions.push(undefined);
				}
			}
			this.topic.questions.splice(index - 1, 0, this.topic.questions.splice(index, 1)[0]);

			this.refresh();
		},
		moveDown: function(q) {
			let index = this.topic.questions.indexOf(q);
			let length = this.topic.questions.length;

			if (index + 1 >= length) {
				let k = index + 1 - length + 1;
				while (k--) {
					this.topic.questions.push(undefined);
				}
			}
			this.topic.questions.splice(index + 1, 0, this.topic.questions.splice(index, 1)[0]);

			this.refresh();
		},
	},
};
</script>

<style scoped>
div.new-question label {
	font-size: 1.1rem;
}
div.card-toolbar {
	align-content: baseline;
}
div.card-toolbar > a > i {
	font-size: 1.5rem;
	color: #fff;
}
div.topic-index {
	position: absolute;
	height: 2.5rem;
	width: 2.5rem;
	left: 0;
	top: 0;
	font-size: 1.4rem;
	line-height: 1rem;
	border-top-left-radius: 5px;
	background-color: #1e1e2d;
	color: #fff;
}
div.topic-type {
	position: absolute;
	height: 2.5rem;
	left: 2.5rem;
	top: 0;
	font-size: 1.4rem;
	line-height: 1rem;
	color: #fff;
	border-left: 1px solid #c1c1c1;
	background-color: #1e1e2d;
}
div.topic-tools {
	position: absolute;
	height: 2.5rem;
	right: 0;
	top: 0;
	background-color: #1e1e2d;
	border-top-right-radius: 5px;
}
div.topic-tools > a {
	height: 100%;
	width: 2.5rem;
}
div.topic-tools > a:hover {
	background-color: #7f7f7f;
}
div.topic-tools > a:hover:last-of-type {
	border-top-right-radius: 5px;
}
div.topic-tools > a > i {
	font-size: 1.5rem;
	color: #fff;
}

a.popover-button {
	font-size: 1rem;
}
</style>
