class General {
    constructor(id) {
        this.id = id;
        this.text = '';
        this.description = '';
        this.translation = [];
        this.picture = null;
        this.pictureOrientation = 'l';
        this.pictureDescription = '';
    }
}

export default General;