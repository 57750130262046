<template>
	<div>
		<div class="form-group">
			<label>{{ $t('VIEWS.Survey.Create.Question.chooseFollow') }}</label>
			<treeselect ref="answer" :multiple="false" v-model="question.option_0" :options="followOptionsCalced" :normalizer="normalizer" :disable-branch-nodes="true" :placeholder="$t('VIEWS.Survey.Create.Question.chooseFollow')" />
		</div>
		<div class="form-group">
			<label v-if="question.type === 'information'">{{ $t('VIEWS.Survey.Create.Question.Text.labelInformation') }}</label>
			<label v-else>{{ $t('GENERAL.Forms.description') }}</label>
			<textarea class="form-control" rows="5" name="description" v-model="question.description"></textarea>
		</div>
	</div>
</template>

<script>
import {klona} from "klona";

export default {
	name: 'SurveyText',
	props: {
		question: Object,
		index: Number,
		followOptions: Array,
	},
	computed: {
		followOptionsCalced() {
			let options = klona(this.followOptions);
			let deactivate = true;

			options.forEach(option => {
				option.children.forEach(q => {
					if (q.id !== this.question.id && deactivate === true) {
						q.isDisabled = true;
					} else if (q.id === this.question.id) {
						q.isDisabled = true;
						deactivate = false;
					}
				});
			});

			return options;
		},
	},
	methods: {
		normalizer: function(node) {
			return {
				id: node.id,
				label: node.label,
				children: node.children,
			};
		},
	},
};
</script>

<style scoped></style>
