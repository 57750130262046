import Configuration from "@/data/survey/Configuration";

class Survey {
	constructor(id) {
		this.id = id;
		this.name = '';
		this.title = '';
		this.sub_title = '';
		this.description = '';
		this.language = 'de';
		this.topics = [];
		this.translation = [];
		this.trigger = [];
		this.start_text = 'Befragung starten!';
		this.next_text = 'Weiter';
		this.previous_text = 'Zurück';
		this.others_text = 'Sonstiges';
		this.no_answer_text = 'Keine Angabe';
		this.finished_header_text = 'Vielen Dank für Ihre Teilnahme.';
		this.finished_body_text = 'Sie können die Befragung jetzt schließen.';
		this.configuration = new Configuration();
	}
}

export default Survey;
