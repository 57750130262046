<template>
	<tr>
		<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
			<div class="ml-9">
				<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.name')">
					<b-input-group>
						<template #prepend>
							<b-input-group-text
							><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.answer') }}</strong></b-input-group-text
							>
						</template>
						<b-form-input type="text" disabled :value="answer.name"></b-form-input>
					</b-input-group>
				</b-form-group>
			</div>
		</th>
		<td v-for="(translation, aii) in answer.translation" :key="'topic-' + ti + '-question-' + qi + '-answer-' + index + '-text-' + aii" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
			<div class="ml-9">
				<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.name')">
					<b-input-group>
						<template #prepend>
							<b-input-group-text
							><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.answer') }}</strong></b-input-group-text
							>
						</template>
						<b-form-input type="text" v-model="answer.translation[aii].name"></b-form-input>
					</b-input-group>
				</b-form-group>
			</div>
		</td>
	</tr>
</template>

<script>
export default {
	name: 'ContactFieldLanguage',
	props: {
		answer: Object,
		index: Number,
		ti: Number,
		qi: Number,
	},
	data: function() {
		return {
			columnWidth: '400px',
		}
	},
	mounted() {
		let contentDiv = document.getElementById('kt_content');
		if (contentDiv != null) {
			let width = contentDiv.clientWidth;
			this.columnWidth =  width / 3 + 'px';
		}
	}
};
</script>

<style scoped></style>
