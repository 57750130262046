<template>
	<div v-frag>
		<tr>
			<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
				<div class="ml-6">
					<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.name')">
						<b-input-group>
							<template #prepend>
								<b-input-group-text
									><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.question') }}</strong></b-input-group-text
								>
							</template>
							<b-form-input type="text" disabled :value="question.text"></b-form-input>
						</b-input-group>
					</b-form-group>
				</div>
			</th>
			<td v-for="(translation, qii) in question.translation.length" :key="'topic-' + ti + '-question-' + index + '-name-' + qii" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
				<div class="ml-6">
					<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.name')">
						<b-input-group>
							<template #prepend>
								<b-input-group-text
									><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.question') }}</strong></b-input-group-text
								>
							</template>
							<b-form-input type="text" v-model="question.translation[qii].text"></b-form-input>
						</b-input-group>
					</b-form-group>
				</div>
			</td>
		</tr>
		<tr>
			<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
				<div class="ml-6">
					<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.description')">
						<b-input-group>
							<template #prepend>
								<b-input-group-text
									><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.question') }}</strong></b-input-group-text
								>
							</template>
							<b-form-textarea readonly rows="3" no-resize :value="question.description"></b-form-textarea>
						</b-input-group>
					</b-form-group>
				</div>
			</th>
			<td v-for="(translation, qii) in question.translation" :key="'topic-' + ti + '-question-' + index + '-description-' + qii" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
				<div class="ml-6">
					<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.description')">
						<b-input-group>
							<template #prepend>
								<b-input-group-text
									><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.question') }}</strong></b-input-group-text
								>
							</template>
							<b-form-textarea rows="3" no-resize v-model="question.translation[qii].description"></b-form-textarea>
						</b-input-group>
					</b-form-group>
				</div>
			</td>
		</tr>
		<template v-for="(answer, ai) in question.answers">
			<AnswerLanguage :answer="answer" :index="ai" :ti="ti" :qi="index" :key="'topic-' + ti + '-question-' + index + '-answer-' + ai + '-text'"></AnswerLanguage>
		</template>
		<template v-if="question.type === 'contact'">
			<ContactFieldLanguage :answer="field" :index="fi" :ti="ti" :qi="index" :key="'topic-' + ti + '-question-' + index + '-answer-' + fi + '-text'" v-for="(field, fi) in question.option_2"></ContactFieldLanguage>
		</template>
	</div>
</template>

<script>
import AnswerLanguage from "@/view/component/survey/language/AnswerLanguage";
import ContactFieldLanguage from "@/view/component/survey/language/ContactFieldLanguage";
export default {
	name: 'QuestionLanguage',
	components: {ContactFieldLanguage, AnswerLanguage},
	props: {
		question: Object,
		index: Number,
		ti: Number,
	},
	data: function() {
		return {
			columnWidth: '400px',
		}
	},
	mounted() {
		let contentDiv = document.getElementById('kt_content');
		if (contentDiv != null) {
			let width = contentDiv.clientWidth;
			this.columnWidth =  width / 3 + 'px';
		}
	}
};
</script>

<style scoped></style>
