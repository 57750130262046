<template>
	<div>
		<div class="row mt-3">
			<div class="d-md-none d-xl-flex col-xl-2">&nbsp;</div>
			<div class="col-md-12 col-xl-8">
				<div class="card card-custom card-stretch gutter-b">
					<div class="card-header border-0 pt-5">
						<h3 class="card-title align-items-start flex-column col-head">
							<span class="card-label font-weight-bolder text-dark">{{ $t('VIEWS.Survey.Create.Trigger.header') }}</span>
							<span class="text-muted mt-3 font-weight-bold font-size-sm"></span>
						</h3>
						<div class="card-toolbar flex-column">
							<a href="#" class="btn btn-success btn-sm my-0" v-on:click.prevent="addTrigger">
								<i class="fas fa-plus"></i>
								<span>{{ $t('VIEWS.Survey.Create.Trigger.addTrigger') }}</span>
							</a>
						</div>
					</div>
					<div class="card-body py-3" v-if="survey.trigger">
						<div class="col-12 px-10 pt-10 mt-5 mb-10 question-container" v-for="(trigger, index) in survey.trigger" :key="'trigger-' + index">
							<div class="question-index d-flex">
								<span class="mx-5 my-auto">{{ $t('VIEWS.Survey.Create.Trigger.trigger') }} #{{ index + 1 }}</span>
							</div>
							<div class="question-tools d-flex">
								<a href="" class="px-2 d-flex" v-on:click.prevent="moveUp(trigger)" v-if="index > 0">
									<i class="fas fa-angle-up" style="vertical-align: middle; font-size: 2rem; text-align: center;"></i>
								</a>
								<a href="" class="px-2 d-flex" v-on:click.prevent="moveDown(trigger)" v-if="index < survey.trigger.length">
									<i class="fas fa-angle-down" style="vertical-align: middle; font-size: 2rem; text-align: center;"></i>
								</a>
								<a href="" class="px-2 d-flex" v-on:click.prevent="copyTrigger(trigger.id)">
									<i class="my-auto mx-auto fas fa-copy"></i>
								</a>
								<a href="" class="px-2 d-flex" v-on:click.prevent="deleteTrigger(trigger.id)">
									<i class="my-auto mx-auto fas fa-trash-alt"></i>
								</a>
							</div>

							<div class="row mt-5">
								<b-tabs class="col-12" content-class="mt-0" justified>
									<b-tab class="border-left border-right border-bottom qcard p-7" :title="$t('VIEWS.Administration.Right.Edit.General.title')" active>
										<div class="col-12 col-md-6">
											<b-form-group :label="$t('VIEWS.Survey.Create.Trigger.question')">
												<b-select v-model="trigger.question_id" :options="questionsComputed" value-field="id" text-field="name" v-on:change="selectQuestion($event, trigger)"></b-select>
											</b-form-group>
										</div>

										<template v-if="trigger.question.type !== 'nps' && trigger.question_id != null && trigger.question_id !== '' && trigger.answer.type !== 'endofsurvey' && trigger.answer.type !== 'import'">
											<div class="col-12 col-md-6">
												<b-form-group :label="$t('VIEWS.Survey.Create.Trigger.answer')">
													<b-select v-model="trigger.answer.answer_id" :options="getAnswers(trigger.question_id)" value-field="id" text-field="text" ></b-select>
												</b-form-group>
											</div>
										</template>
										<template v-else-if="trigger.question.type === 'nps' && trigger.question_id != null && trigger.question_id !== '' && trigger.answer.type !== 'endofsurvey' && trigger.answer.type !== 'import'">
											<div class="col-6 col-md-3" v-if="trigger.question_id != null && trigger.question_id !== ''">
												<b-form-group :label="$t('VIEWS.Survey.Create.Question.Nps.from')">
													<b-select v-model="trigger.answer.nps.from" :options="npsNumbers" value-field="id" text-field="id"/>
												</b-form-group>
											</div>
											<div class="col-6 col-md-3" v-if="trigger.question_id != null && trigger.question_id !== ''">
												<b-form-group :label="$t('VIEWS.Survey.Create.Question.Nps.to')">
													<b-select v-model="trigger.answer.nps.to" :options="npsNumbers" value-field="id" text-field="id"/>
												</b-form-group>
											</div>
										</template>

										<template v-if="(trigger.question.type !== 'nps' && trigger.answer.answer_id != null && trigger.answer.answer_id !== '')
									|| (trigger.question.type === 'nps' && trigger.answer.nps.from !== null && trigger.answer.nps.from !== '' && trigger.answer.nps.to !== null && trigger.answer.nps.to !== '') || trigger.answer.type === 'endofsurvey' || trigger.answer.type === 'import'">
											<div class="col-12 col-md-6">
												<b-form-group :label="$t('VIEWS.Survey.Create.Trigger.type')">
													<b-select v-model="trigger.message_template.message_type" :options="templateTypes" value-field="id" text-field="name"></b-select>
												</b-form-group>
											</div>

											<div class="col-12 col-md-6" v-if="trigger.message_template.message_type != null && trigger.message_template.message_type !== ''">
												<b-form-group :label="$t('VIEWS.Survey.Create.Trigger.template')">
													<b-select v-model="trigger.template_id" :options="getTemplates(trigger.message_template.message_type)" value-field="id" text-field="name" ></b-select>
													<b-form-invalid-feedback v-if="trigger.template_id != null && trigger.template_id !== ''" :state="isImportValid(trigger.template_id)">
														{{ $t('VIEWS.Survey.Create.Trigger.importError') }}
													</b-form-invalid-feedback>
												</b-form-group>
											</div>

											<div class="col-12 col-md-6" v-if="trigger.message_template.message_type !== 'whatsapp' && trigger.template_id != null && trigger.template_id !== '' && isRecipientImport(trigger.template_id)">
												<b-form-group :label="$t('VIEWS.Survey.Create.Trigger.importAddress')">
													<b-select v-model="trigger.answer.import_address" :options="importFields" value-field="id" text-field="name" ></b-select>
												</b-form-group>
											</div>
										</template>
									</b-tab>
									<b-tab class="border-left border-right border-bottom qcard p-7" :title="$t('VIEWS.Survey.Create.Question.tabOptions')">
										<b-form-group label="##Ausführungszeitpunkt">
											<div class="d-flex">
												<div class="col-6">
													<b-form-radio-group
														v-model="trigger.answer.execution_type"
														:options="[
														{ text: '##Sofort', value: 'now' },
														{ text: '##Zeitverzögert', value: 'delay' },
														{ text: '##Bestimmte Uhrzeit', value: 'time' },
													]"
														buttons
														button-variant="outline-primary"
														size="sm"
													></b-form-radio-group>
												</div>
												<div class="col-6 col-md-3" v-if="trigger.answer.execution_type && trigger.answer.execution_type === 'delay'">
													<b-input type="number" min="0" max="3600" v-model="trigger.answer.execution_time"></b-input>
												</div>
												<div class="col-6 col-md-3" v-if="trigger.answer.execution_type && trigger.answer.execution_type === 'time'">
													<b-input type="text" v-model="trigger.answer.execution_time"></b-input>
												</div>
											</div>

										</b-form-group>

									</b-tab>
								</b-tabs>

							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="d-md-none d-xl-flex col-xl-2"></div>
		</div>
	</div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {PRINOR_TOASTS} from "@/core/services/toast.service";
import { v4 as uuidv4 } from 'uuid';
import {klona} from "klona/lite";

export default {
	name: "SurveyTrigger",
	props: {
		survey: Object,
		personalised: Boolean,
	},
	data: function() {
		return {
			templates: [],
			templateTypes: [
				{
					id: 'email',
					name: this.$t('VIEWS.Survey.Create.Trigger.email'),
				},
				{
					id: 'sms',
					name: this.$t('VIEWS.Survey.Create.Trigger.sms'),
				},
				{
					id: 'whatsapp',
					name: 'WhatsApp',
				},
			],
			npsNumbers: [
				{ id: 0 },
				{ id: 1 },
				{ id: 2 },
				{ id: 3 },
				{ id: 4 },
				{ id: 5 },
				{ id: 6 },
				{ id: 7 },
				{ id: 8 },
				{ id: 9 },
				{ id: 10 },
			],
			importFields: [
				{
					id: 'customer',
					name: this.$t('VIEWS.Survey.Create.Trigger.customerMail')
				},
				{
					id: 'employee',
					name: this.$t('VIEWS.Survey.Create.Trigger.employeeMail')
				}
			],
		};
	},
	computed: {
		questionsComputed: function() {
			let arr = [];
			arr.push({ id: 'endofsurvey', name: this.$t('VIEWS.Survey.Create.Trigger.endOfSurvey') });

			if (this.personalised === true) {
				arr.push({ id: 'import', name: this.$t('VIEWS.Survey.Create.Trigger.duringImport') });
			}

			this.survey.topics.forEach(topic => {
				let topicArr = { id: null, name: topic.name, options: [] };

				topic.questions.forEach(question => {
					if (question.type === 'radio' || question.type === 'checkbox' || question.type === 'smileys' || question.type === 'nps') {
						topicArr.options.push({
							id: question.id,
							name: question.text,
							type: question.type,
						});
					}
				});

				if (topicArr.options.length > 0) {
					arr.push(topicArr);
				}
			});

			return arr;
		},
	},
	mounted() {
		this.onGet();
		if (this.survey.trigger) {
			this.survey.trigger.forEach(trigger => {
				if (!('execution_type' in trigger.answer))
					trigger.answer.execution_type = 'now';

				if (!('execution_time' in trigger.answer))
					trigger.answer.execution_time = null;
			});
		}
	},
	methods: {
		moveUp: function(t) {
			let index = this.survey.trigger.indexOf(t);
			let length = this.survey.trigger.length;

			if (index - 1 >= length) {
				let k = index - 1 - length + 1;
				while (k--) {
					this.survey.trigger.push(undefined);
				}
			}
			this.survey.trigger.splice(index - 1, 0, this.survey.trigger.splice(index, 1)[0]);
		},
		moveDown: function(t) {
			let index = this.survey.trigger.indexOf(t);
			let length = this.survey.trigger.length;

			if (index + 1 >= length) {
				let k = index + 1 - length + 1;
				while (k--) {
					this.survey.trigger.push(undefined);
				}
			}
			this.survey.trigger.splice(index + 1, 0, this.survey.trigger.splice(index, 1)[0]);
		},
		getAnswers: function(id) {
			let arr = [];

			this.survey.topics.forEach(topic => {
				let qArr = topic.questions.filter(value => { return value.id === id; });
				if (qArr.length > 0) {
					arr = qArr[0].answers;
				}
			});

			return arr;
		},
		getTemplates: function(type) {
			let arr = [];
			arr.push({ id: null, name: this.$t('VIEWS.Survey.Create.Trigger.noSelected') });

			let temps = this.templates.filter(value => { return value.message_type === type; });
			temps.forEach(value => {
				arr.push({
					id: value.id,
					name: value.name,
				});
			});

			return arr;
		},
		isRecipientImport: function(id) {
			let templateArr = this.templates.filter(value => { return value.id === id });
			if (templateArr.length === 1) {
				if (templateArr[0].settings.recipient_type === "import" && this.personalised === true) {
					return true;
				}
			}

			return false;
		},
		isImportValid: function(id) {
			let templateArr = this.templates.filter(value => { return value.id === id });
			if (templateArr.length === 1) {
				if (templateArr[0].settings.recipient_type === "import" && this.personalised === true) {
					return true;
				}
				if (templateArr[0].settings.recipient_type !== "import") {
					return true;
				}
			}

			return false;
		},
		selectQuestion: function(id, trigger) {
			if (id === "endofsurvey") {
				trigger.question_id = "endofsurvey";
				trigger.answer.answer_id = '';
				trigger.answer.nps.from = null;
				trigger.answer.nps.to = null;
				trigger.answer.type = "endofsurvey";
			}
			else if (id === "import") {
				trigger.question_id = "import";
				trigger.answer.answer_id = '';
				trigger.answer.nps.from = null;
				trigger.answer.nps.to = null;
				trigger.answer.type = "import";
			}
			else {
				this.survey.topics.forEach(topic => {
					let qArr = topic.questions.filter(value => { return value.id === id; });
					if (qArr.length > 0) {
						trigger.question.type = qArr[0].type;
						trigger.question_id = qArr[0].id;
						trigger.answer.answer_id = '';
						trigger.answer.nps.from = null;
						trigger.answer.nps.to = null;
						trigger.answer.type = "question";
					}
				});
			}
		},
		addTrigger: function() {
			this.survey.trigger.push({
				id: uuidv4(),
				question_id: '',
				answer: {
					answer_id: '',
					nps: {
						from: null,
						to: null,
					},
					type: "question",
					import_address: '',
					execution_type: 'now',
					execution_time: null
				},
				question: {
					type: '',
				},
				message_template: {
					message_type: '',
				},
				template_id: '',
			});
		},
		copyTrigger: function(id) {
			let arr = this.survey.trigger.filter(value => { return value.id === id; });
			if (arr.length === 1) {
				let newTrigger = klona(arr[0]);
				newTrigger.id = uuidv4();
				this.survey.trigger.push(newTrigger);
			}
		},
		deleteTrigger: function(id) {
			this.survey.trigger = this.survey.trigger.filter(function(value) {
				return value.id !== id;
			});
		},
		showTopic: function(topic) {
			let show = false;

			if (topic.questions.length === 0)
				return false;

			topic.questions.forEach(value => {
				if (value.type === 'radio' || value.type === 'checkbox' || value.type === 'smileys') {
					show = true;
				}
			});

			return show;
		},
		onGet: function() {
			this.get().then(data => {
				this.templates = data.data;
			});
		},
		get: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('message-template/all').then(data => {
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === false) {
							PRINOR_TOASTS.general.error(this, data.data.error);
						} else {
							resolve(data.data);
						}
					}
				});
			});
		},
	},
}
</script>

<style scoped>
	span.answer-text {
		font-size: 1.2rem;
	}
	.col-head {
		align-self: center;
	}
	.qcard {
		border-bottom-left-radius: 0.42rem;
		border-bottom-right-radius: 0.42rem;
	}
	.picContainer {
		border-radius: 0.42rem;
	}
	h3.card-title > input {
		font-size: 1.275rem;
		font-weight: 600 !important;
		color: #181c32 !important;
		width: 100%;
	}
	div.question-container {
		border: 1px solid #d4d4d4;
		border-radius: 5px;
	}
	div.question-index {
		position: absolute;
		height: 2.5rem;
		left: 0;
		top: 0;
		font-size: 1.4rem;
		line-height: 1rem;
		border-top-left-radius: 5px;
		background-color: #1e1e2d;
		color: #fff;
	}
	div.question-type {
		position: absolute;
		height: 2.5rem;
		left: 2.5rem;
		top: 0;
		font-size: 1.4rem;
		line-height: 1rem;
		border-left: 1px solid #c1c1c1;
		background-color: #1e1e2d;
		color: #fff;
	}
	div.question-draggable {
		position: absolute;
		height: 2.5rem;
		width: 2.5rem;
		left: calc(50% - 1.5rem);
		top: 0;
	}
	div.question-draggable i {
		font-size: 1.8rem;
	}
	div.question-tools {
		position: absolute;
		height: 2.5rem;
		right: 0;
		top: 0;
		background-color: #1e1e2d;
		border-top-right-radius: 5px;
		color: #fff;
	}
	div.question-tools > a {
		height: 100%;
		width: 2.5rem;
	}
	div.question-tools > a:hover {
		background-color: #7f7f7f;
	}
	div.question-tools > a:hover:last-of-type {
		border-top-right-radius: 5px;
	}
	div.question-tools > a > i {
		font-size: 1.5rem;
		color: #fff;
	}
	.empty-chart-text {
		top: calc(50% - 1.5rem);
		font-size: 3rem;
		text-align: center;
		color: darkgray;
	}
</style>