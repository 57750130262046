<template>
	<div id="surveyEdit">
		<ContentLoading size="1.5" v-if="pageProcesses.isLoading"></ContentLoading>
		<template v-if="!pageProcesses.isLoading">
			<b-tabs content-class="" justified>
				<b-tab :title="$t('MENU.Survey.editSurvey')" active>
					<div class="row mt-3">
						<div class="d-md-none d-xl-flex col-xl-2" id="sidebar-nav">
<!--							<div id="sidebar-toc" :style="tocStyle" class="pt-2">-->
<!--								<ul class="ml-4">-->
<!--									<li v-for="(topic, index) in survey.topics" :key="topic.id" class="mb-4">-->
<!--										<span v-on:click.prevent="scrollToElement(topic.id)" class="cursor-pointer">{{ (index + 1) }} - {{ topic.name }}</span>-->
<!--										<ul class="ml-8" v-if="topic.questions.length > 0">-->
<!--											<li v-for="(question, qindex) in topic.questions" :key="question.id" class="mb-2">-->
<!--												<span v-on:click.prevent="scrollToElement(question.id)" class="cursor-pointer">{{ (qindex + 1) }} - {{ question.text }}</span>-->
<!--											</li>-->
<!--										</ul>-->
<!--									</li>-->
<!--								</ul>-->
<!--							</div>-->
						</div>
						<div class="col-md-12 col-xl-8">
							<div class="card card-custom card-stretch gutter-b">
								<div class="card-header border-0 pt-5 ">
									<h3 class="card-title align-items-start flex-column">
										<span class="card-label font-weight-bolder text-dark" v-if="!isEditMode">{{ $t('MENU.Survey.createSurvey') }}</span>
										<span class="card-label font-weight-bolder text-dark" v-if="isEditMode">{{ $t('MENU.Survey.editSurvey') }}</span>
										<span class="text-muted mt-3 font-weight-bold font-size-sm">&nbsp;</span>
									</h3>
								</div>
								<div class="card-body py-3">
									<b-tabs content-class="mt-0" justified>
										<b-tab class="border-left border-right border-bottom qcard p-7" :title="$t('VIEWS.Administration.Right.Edit.General.title')" active>
											<div class="row">
												<div class="col-12 col-md-6">
													<div class="form-group">
														<label>{{ $t('VIEWS.Survey.Create.General.name') }}</label>
														<b-form-input v-model="survey.name" name="name" type="text"></b-form-input>
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-12 col-md-6">
													<div class="form-group">
														<label>{{ $t('VIEWS.Survey.Create.General.title') }}</label>
														<b-form-input ref="title" v-model="survey.title" name="title" type="text" :id="'survey-title'"></b-form-input>
														<b-popover :target="'survey-title'" triggers="focus" placement="top" v-if="personalised === true">
															<h6>{{ $t('VIEWS.Survey.Create.Placeholder.title') }}</h6>
															<template v-for="(name, value) in personalPlaceholder">
																<b-button v-if="value !== 'that'" variant="primary" class="m-2" v-on:click.prevent="personalPlaceholder.insertPlaceholder( name, 'survey', 'title')" :key="'survey-title-' + value">{{ $t('VIEWS.Survey.Create.Placeholder.' + value) }}</b-button>
															</template>
														</b-popover>
													</div>
												</div>
												<div class="col-12 col-md-6">
													<div class="form-group">
														<label>{{ $t('VIEWS.Survey.Create.General.subtitle') }}</label>
														<input ref="subtitle" class="form-control" type="text" name="subtitle" v-model="survey.sub_title" :id="'survey-subtitle'" />
														<b-popover :target="'survey-subtitle'" triggers="focus" placement="top" v-if="personalised === true">
															<h6>{{ $t('VIEWS.Survey.Create.Placeholder.title') }}</h6>
															<template v-for="(name, value) in personalPlaceholder">
																<b-button v-if="value !== 'that'" variant="primary" class="m-2" v-on:click.prevent="personalPlaceholder.insertPlaceholder( name, 'survey', 'subtitle')" :key="'survey-subtitle-' + value">{{ $t('VIEWS.Survey.Create.Placeholder.' + value) }}</b-button>
															</template>
														</b-popover>
													</div>
												</div>
												<div class="col-12">
													<div class="form-group">
														<label>{{ $t('VIEWS.Survey.Create.General.description') }}</label>
														<textarea ref="description" class="form-control" rows="5" name="description" v-model="survey.description" :id="'survey-description'"></textarea>
														<b-popover :target="'survey-description'" triggers="focus" placement="top" v-if="personalised === true">
															<h6>{{ $t('VIEWS.Survey.Create.Placeholder.title') }}</h6>
															<template v-for="(name, value) in personalPlaceholder">
																<b-button v-if="value !== 'that'" variant="primary" class="m-2" v-on:click.prevent="personalPlaceholder.insertPlaceholder( name, 'survey', 'description')" :key="'survey-description-' + value">{{ $t('VIEWS.Survey.Create.Placeholder.' + value) }}</b-button>
															</template>
														</b-popover>
													</div>
												</div>
											</div>
										</b-tab>
										<b-tab class="border-left border-right border-bottom qcard p-7" :title="$t('VIEWS.Survey.Create.General.terms')">
											<div class="row">
												<div class="col-12">
													<b-form-group :label="$t('VIEWS.Survey.Create.General.startSurveyButton')">
														<b-form-input type="text" v-model="survey.start_text"></b-form-input>
													</b-form-group>
												</div>
												<div class="col-12 col-md-6">
													<b-form-group :label="$t('VIEWS.Survey.Create.General.backButton')">
														<b-form-input type="text" v-model="survey.previous_text"></b-form-input>
													</b-form-group>
												</div>
												<div class="col-12 col-md-6">
													<b-form-group :label="$t('VIEWS.Survey.Create.General.nextButton')">
														<b-form-input type="text" v-model="survey.next_text"></b-form-input>
													</b-form-group>
												</div>

												<div class="col-12 col-md-6">
													<b-form-group :label="$t('VIEWS.Survey.Create.General.othersTerm')">
														<b-form-input type="text" v-model="survey.others_text"></b-form-input>
													</b-form-group>
												</div>
												<div class="col-12 col-md-6">
													<b-form-group :label="$t('VIEWS.Survey.Create.General.noAnswerTerm')">
														<b-form-input type="text" v-model="survey.no_answer_text"></b-form-input>
													</b-form-group>
												</div>

												<div class="col-12 col-md-6">
													<b-form-group :label="$t('VIEWS.Survey.Create.General.finishedHeaderTerm')">
														<b-form-input type="text" v-model="survey.finished_header_text"></b-form-input>
													</b-form-group>
												</div>
												<div class="col-12 col-md-6">
													<b-form-group :label="$t('VIEWS.Survey.Create.General.finishedBodyTerm')">
														<b-form-input type="text" v-model="survey.finished_body_text"></b-form-input>
													</b-form-group>
												</div>
											</div>
										</b-tab>
										<b-tab class="border-left border-right border-bottom qcard p-7" :title="$t('VIEWS.Survey.Create.Question.tabOptions')">
											<div class="w-100" v-if="survey && survey.configuration">
												<b-form-group :label="$t('VIEWS.Survey.Create.General.randomized')">
													<b-form-radio-group
														v-model="survey.configuration.is_randomized"
														:options="[
															{ text: $t('GENERAL.General.yes'), value: true },
															{ text: $t('GENERAL.General.no'), value: false },
														]"
														buttons
														button-variant="outline-primary"
														size="sm"
													></b-form-radio-group>
												</b-form-group>
											</div>
											<div class="row" v-if="survey && survey.configuration && survey.configuration.is_randomized === true">
												<div class="col-12 col-md-6">
													<b-form-group :label="$t('VIEWS.Survey.Create.General.randomizerMode')">
														<b-form-select :options="[{ value: 'survey', text: $t('GENERAL.General.survey') }, { value: 'topic', text: $t('GENERAL.General.topic') }]" value-field="value" text-field="text" v-model="survey.configuration.randomizer_mode"></b-form-select>
													</b-form-group>
												</div>
												<div class="col-12 col-md-6">
													<b-form-group :label="$t('VIEWS.Survey.Create.General.randomizerStart')">
														<b-form-select :options="survey.topics" value-field="id" text-field="name" v-model="survey.configuration.randomizer_start"></b-form-select>
													</b-form-group>
												</div>
											</div>
											<div class="row">
												<div class="col-12 col-md-6">
													<b-button variant="success">##Language options</b-button>
												</div>
											</div>
										</b-tab>
									</b-tabs>
								</div>
							</div>
						</div>
						<div class="d-md-none d-xl-flex col-xl-2">&nbsp;</div>
					</div>
					<div class="row" v-for="(topic, index) in survey.topics" :key="'t-' + index + '-' + topic.id">
						<div class="col-12">
							<hr class="topic-splitter mb-10" />
						</div>
						<div class="d-md-none d-xl-flex col-xl-2">&nbsp;</div>
						<div class="col-md-12 col-xl-8 container">
							<SurveyTopic
								:topic="topic"
								:index="index"
								:amount="survey.topics.length - 1"
								:followOptions="followOptions"
								:personalised="personalised"
								@copyTopic="copyTopic"
								@deleteTopic="deleteTopic"
								@moveUp="moveUp"
								@moveDown="moveDown"
								@setRegressionTarget="setRegressionTarget"
								@refreshOptions="calcFollowOptions"
							></SurveyTopic>
						</div>
						<div class="d-md-none d-xl-flex col-xl-2">&nbsp;</div>
					</div>
				</b-tab>
				<b-tab :title="$t('VIEWS.Survey.Create.Language.title')">
					<SurveyLanguage :survey="survey" @addTranslation="addTranslation(false)" @changeLanguage="changeLanguage" @deleteTranslation="deleteTranslation"></SurveyLanguage>
				</b-tab>
				<b-tab :title="$t('VIEWS.Survey.Create.Trigger.title')">
					<SurveyTrigger :survey="survey" :personalised="personalised"></SurveyTrigger>
				</b-tab>
			</b-tabs>
		</template>
	</div>
</template>

<script>
import SurveyTopic from '@/view/component/survey/SurveyTopic';
import { v4 as uuidv4 } from 'uuid';
import Survey from '@/data/survey/Survey';
import Topic from '@/data/survey/Topic';
import { klona } from 'klona/lite';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ContentLoading from '@/view/component/misc/ContentLoading';
import ApiService from '@/core/services/api.service';
import Nps from '@/data/survey/type/Nps';
import Text from '@/data/survey/type/Text';
import Option from '@/data/survey/type/Option';
import Answer from '@/data/survey/type/Answer';
import Matrix from '@/data/survey/type/Matrix';
import { PRINOR_TOASTS } from '@/core/services/toast.service';
// import { required } from 'vuelidate/lib/validators';
// import { validationMixin } from 'vuelidate';
import { PRINOR_ACTIONS, PRINOR_PROCESSES } from '@/core/services/actions.service';
import SurveyLanguage from '@/view/component/survey/language/SurveyLanguage';
import TranslationSurvey from '@/data/survey/translation/Survey';
import TranslationTopic from '@/data/survey/translation/Topic';
import TranslationQuestion from '@/data/survey/translation/Question';
import TranslationAnswer from '@/data/survey/translation/Answer';
import MatrixOption from '@/data/survey/type/MatrixOption';
import TranslationMatrixOption from '@/data/survey/translation/MatrixOption';
import { PRINOR_PERMISSION } from '@/core/services/permissions.service';
import { mapGetters } from 'vuex';
import { SET_ACTION } from '@/core/services/store/actions.module';
import { Contact, ContactField } from '@/data/survey/type/Contact';
import Placeholder from "@/data/survey/Placeholder";
import SurveyTrigger from "@/view/component/survey/trigger/SurveyTrigger";
import Swal from "sweetalert2";

export default {
	name: 'SurveyEdit',
	components: {SurveyTrigger, SurveyLanguage, ContentLoading, SurveyTopic },
	mounted() {
		this.initSurvey(null);
	},
	watch: {
		topics: function(newVal) {
			this.topics = newVal;
			this.calcFollowOptions();
		},
		pageProcesses: {
			deep: true,
			handler: function(newVal) {
				PRINOR_ACTIONS.setProcessing(this, this.saveButtonId, newVal.isUpdating);
			},
		},
		getPermissions: {
			deep: true,
			handler: function() {
				PRINOR_ACTIONS.setDisabled(this, this.saveButtonId, !this.hasPermission('action.survey.save'));
			},
		},
		$route: {
			deep: true,
			handler: function(newVal) {
				if ('id' in newVal.params) {
					this.initSurvey(newVal.params.id);
				}
			}
		},
		survey: {
			deep: true,
			handler: function() {
				this.unsavedChanges = true;
			}
		}
	},
	computed: {
		...mapGetters(['pageProcesses']),
		...mapGetters(['getPermissions']),
		topics: {
			get() {
				return this.survey.topics;
			},
			set(val) {
				this.survey.topics = val;
			},
		},
		personalPlaceholder: function() {
			return new Placeholder(this);
		},
		tocStyle: function() {
			let sidebarElement = document.getElementById("sidebar-nav");

			if (sidebarElement != null && sidebarElement.clientWidth > 5) {
				return { width: 'calc(' + sidebarElement.offsetWidth + "px - .5rem)" };
			}
			else {
				return {};
			}
		}
	},
	data: function() {
		return {
			followOptions: [],
			survey: {},
			isUpdating: false,
			isLoading: true,
			isEditMode: false,
			saveButtonId: '',
			addTopicButtonId: '',
			showPreviewButtonId: '',
			personalised: false,
			unsavedChanges: false,
		};
	},
	// mixins: [validationMixin],
	// validations: {
	// 	survey: {
	// 		name: {
	// 			required,
	// 		},
	// 		title: {
	// 			required,
	// 		},
	// 		topics: {
	// 			required,
	// 			$each: {
	// 				name: {
	// 					required,
	// 				},
	// 				questions: {
	// 					required,
	// 					$each: {
	// 						text: {
	// 							required,
	// 						},
	// 					},
	// 				},
	// 			},
	// 		},
	// 	},
	// },
	methods: {
		scrollToElement: function(id) {
			let element = document.getElementById(id);
			if (element != null) {
				const yOffset = -150;
				window.scrollTo({
					top: element.getBoundingClientRect().top + window.pageYOffset + yOffset,
					behavior: 'smooth'
				});
			}
		},
		initSurvey: function(toId) {
			this.survey = new Survey(uuidv4());

			this.$store.dispatch(SET_ACTION, []);
			this.showPreviewButtonId = PRINOR_ACTIONS.create(this, this.$t('VIEWS.Survey.Create.General.preview'), this.showPreview, 'secondary', '', false);
			this.addTopicButtonId = PRINOR_ACTIONS.create(this, this.$t('VIEWS.Survey.Create.General.addTopic'), this.addTopic, 'primary', '', false);
			this.saveButtonId = PRINOR_ACTIONS.create(this, this.$t('GENERAL.Forms.save'), this.saveSurvey, 'success', '', false, !this.hasPermission('action.survey.save'));

			if ('id' in this.$route.params || toId != null) {
				if (toId != null) {
					this.$route.params.id = toId;
				}

				this.$store.dispatch(SET_BREADCRUMB, [
					{
						title: this.$t('MENU.Survey.surveys'),
						route: '/survey',
					},
					{ title: this.$t('MENU.Survey.editSurvey') },
				]);
				this.isEditMode = true;

				PRINOR_PROCESSES.isLoading();
				this.get().then(data => {
					PRINOR_PROCESSES.reset();
					this.personalised = data.data.personalised;
					this.createData(data.data.survey);
					this.createTranslation(data.data.translation);
					this.unsavedChanges = false;
					let sidebarElement = document.getElementById("sidebar-nav");
					if (sidebarElement != null) {
						let sidebarToc = document.getElementById("sidebar-toc");
						sidebarToc.style.width = 'calc(' + sidebarElement.offsetWidth + "px - .5rem)";
					}
				});
			} else {
				this.survey = new Survey(uuidv4());

				this.$store.dispatch(SET_BREADCRUMB, [
					{
						title: this.$t('MENU.Survey.surveys'),
						route: '/survey',
					},
					{ title: this.$t('MENU.Survey.createSurvey') },
				]);

				this.isLoading = false;
			}

			this.calcFollowOptions();
		},
		showPreview: function() {
			let routeData = this.$router.resolve({name: 'survey-preview', params: { id: this.survey.id }});
			window.open(routeData.href, '_blank');
		},
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		createTranslation: function(translation) {
			let that = this;
			translation.languages.forEach(lang => {
				if (lang !== that.survey.language) {
					that.addTranslation(true);

					let survey = that.survey.translation[that.survey.translation.length - 1];
					if (survey !== null) {
						this.languageText(lang, survey, translation.survey, null, null, null, 'name');
						this.languageText(lang, survey, translation.survey, null, null, null, 'title');
						this.languageText(lang, survey, translation.survey, null, null, null, 'sub_title');
						this.languageText(lang, survey, translation.survey, null, null, null, 'description');
						this.languageText(lang, survey, translation.survey, null, null, null, 'start_text', 'start');
						this.languageText(lang, survey, translation.survey, null, null, null, 'previous_text', 'previous');
						this.languageText(lang, survey, translation.survey, null, null, null, 'next_text', 'next');
						this.languageText(lang, survey, translation.survey, null, null, null, 'others_text', 'others');
						this.languageText(lang, survey, translation.survey, null, null, null, 'no_answer_text', 'no_answer');
						this.languageText(lang, survey, translation.survey, null, null, null, 'finished_header_text', 'finished_header');
						this.languageText(lang, survey, translation.survey, null, null, null, 'finished_body_text', 'finished_body');
					}

					that.survey.topics.forEach(topic => {
						let t = topic.translation[topic.translation.length - 1];
						this.languageText(lang, t, translation.topics, topic.id, null, null, 'name');
						this.languageText(lang, t, translation.topics, topic.id, null, null, 'title');
						this.languageText(lang, t, translation.topics, topic.id, null, null, 'description');

						topic.questions.forEach(question => {
							if (question.type !== 'matrix') {
								let q = question.translation[question.translation.length - 1];
								this.languageText(lang, q, translation.topics, topic.id, question.id, null, 'text', 'name');
								this.languageText(lang, q, translation.topics, topic.id, question.id, null, 'description');

								if (question.type === 'radio' || question.type === 'checkbox') {
									question.answers.forEach(answer => {
										let a = answer.translation[answer.translation.length - 1];
										this.languageText(lang, a, translation.topics, topic.id, question.id, answer.id, 'text', 'name');
										// if ('answers' in translation.topics[topic.id].questions[question.id] && answer.id in translation.topics[topic.id].questions[question.id].answers) {
										// 	this.languageText(lang, a, translation.topics, topic.id, question.id, answer.id, 'text', 'name');
										// }
									});
								}
							} else {
								if (lang in translation.topics && topic.id in translation.topics[lang] && question.id in translation.topics[lang][topic.id].questions) {
									if ('option_0' in translation.topics[lang][topic.id].questions[question.id] && translation.topics[lang][topic.id].questions[question.id].option_0 != null) {
										let options = translation.topics[lang][topic.id].questions[question.id].option_0.split(';');
										let i = 0;
										question.options.forEach(option => {
											let o = option.translation[option.translation.length - 1];
											o.code = lang;
											o.text = options[i];
											i++;
										});
									}

									if ('option_1' in translation.topics[lang][topic.id].questions[question.id]) {
										question.translation[question.translation.length - 1].text = translation.topics[lang][topic.id].questions[question.id].option_1;
										question.translation[question.translation.length - 1].code = lang;
									}

									question.questions.forEach(q => {
										let qt = q.translation[question.translation.length - 1];
										this.languageText(lang, qt, translation.topics, topic.id, question.id, null, 'text', 'name');
									});
								}
							}
						});
					});

					this.changeLanguage(lang, this.survey.translation.length - 1);
				}
			});
		},
		languageText: function(lang, obj, translation, topicId, questionId, answerId, key, keyTranslation = '') {
			if (keyTranslation === '') {
				keyTranslation = key;
			}

			if (lang in translation) {
				if (topicId in translation[lang]) {
					if (questionId == null && keyTranslation in translation[lang][topicId]) {
						obj[key] = translation[lang][topicId][keyTranslation];
						obj.code = lang;
					}

					if (questionId != null && questionId in translation[lang][topicId].questions) {
						if (answerId == null && keyTranslation in translation[lang][topicId].questions[questionId]) {
							obj[key] = translation[lang][topicId].questions[questionId][keyTranslation];
							obj.code = lang;
						}

						if (answerId != null && answerId in translation[lang][topicId].questions[questionId].answers) {
							if (keyTranslation in translation[lang][topicId].questions[questionId].answers[answerId]) {
								obj[key] = translation[lang][topicId].questions[questionId].answers[answerId][keyTranslation];
								obj.code = lang;
							}
						}
					}
				}

				if (topicId == null) {
					if (keyTranslation in translation[lang]) {
						obj[key] = translation[lang][keyTranslation];
						obj.code = lang;
					}
				}
			}

			return false;
		},
		addTranslation: function(creation) {
			this.survey.topics.forEach(topic => {
				topic.translation.push(new TranslationTopic());
				topic.questions.forEach(question => {
					question.translation.push(new TranslationQuestion());

					if (question.type === 'radio' || question.type === 'checkbox') {
						question.answers.forEach(answer => {
							answer.translation.push(new TranslationAnswer());
						});
					}

					if (question.type === 'matrix') {
						question.options.forEach(option => {
							option.translation.push(new TranslationMatrixOption());
						});
						question.questions.forEach(q => {
							q.translation.push(new TranslationQuestion());
						});
					}

					if (question.type === 'contact') {
						if (question.option_2 !== null && creation === false) {
							question.option_2.forEach(field => {
								field.translation.push(new TranslationAnswer());
							});
						}
					}
				});
			});
			this.survey.translation.push(new TranslationSurvey());
		},
		deleteTranslation: function(index) {
			this.survey.topics.forEach(topic => {
				topic.questions.forEach(question => {
					if (question.type === 'radio' || question.type === 'checkbox') {
						question.answers.forEach(answer => {
							answer.translation.splice(index, 1);
						});
					}

					if (question.type === 'matrix') {
						question.options.forEach(option => {
							option.translation.splice(index, 1);
						});
						question.questions.forEach(q => {
							q.translation.splice(index, 1);
						});
					}

					question.translation.splice(index, 1);
				});

				topic.translation.splice(index, 1);
			});

			this.survey.translation.splice(index, 1);
		},
		changeLanguage: function(value, index) {
			this.survey.translation[index].code = value;
			this.survey.topics.forEach(topic => {
				topic.translation[index].code = value;
				topic.questions.forEach(question => {
					question.translation[index].code = value;

					if (question.type === 'radio' || question.type === 'checkbox') {
						question.answers.forEach(answer => {
							answer.translation[index].code = value;
						});
					}
					if (question.type === 'contact') {
						question.option_2.forEach(field => {
							field.translation[index].code = value;
						});
					}
					if (question.type === 'matrix') {
						question.questions.forEach(mq => {
							mq.translation[index].code = value;
						});
						question.options.forEach(mo => {
							mo.translation[index].code = value;
						});
					}
				});
			});
		},
		createData: function(data) {
			let that = this;
			this.survey = new Survey(data.id);
			this.survey.name = data.name;
			this.survey.title = data.title;
			this.survey.sub_title = data.sub_title;
			this.survey.description = data.description;
			this.survey.language = data.language;
			this.survey.trigger = data.survey_triggers;
			this.survey.configuration.is_randomized = data.is_randomized;
			this.survey.configuration.randomizer_mode = data.randomizer_mode;
			this.survey.configuration.randomizer_start = data.randomizer_start;
			this.survey.start_text = data.start_text;
			this.survey.next_text = data.next_text;
			this.survey.previous_text = data.previous_text;
			this.survey.others_text = data.others_text;
			this.survey.no_answer_text = data.no_answer_text;
			this.survey.finished_header_text = data.finished_header_text;
			this.survey.finished_body_text = data.finished_body_text;

			this.survey.trigger.forEach(value => {
				if (value.question_id == null) {
					value.question_id = value.answer.type;
				}

				if (value.question == null) {
					value.question = {
						type: '',
					};
				}
			});

			data.topics.forEach(st => {
				let topic = new Topic(st.id);
				topic.name = st.name;
				topic.title = st.title;
				topic.description = st.description;
				topic.configuration.is_randomized = st.is_randomized;

				let matrixQuestion = null;
				let matrixQuestionText = null;

				st.questions.forEach(q => {
					let question = null;

					if ((q.type !== 'matrix' && matrixQuestion != null) || (matrixQuestion != null && matrixQuestionText !== q.option_1)) {
						topic.questions.push(matrixQuestion);
						matrixQuestion = null;
						matrixQuestionText = null;
					}

					if (q.type === 'nps') {
						question = new Nps(q.id);
						question.text = q.name;
						question.description = q.description;
						question.is_regression = q.is_regression;
						question.is_regression_target = q.is_regression_target;
						question.is_required = q.is_required;
						question.picture = q.image;
						question.pictureOrientation = q.image_align;
						question.pictureDescription = q.image_title;
						question.personality_active = q.personality_active;
						if (q.option_2 != null)
							question.option_2 = q.option_2;
						question.loadConditions(q);
					} else if (q.type === 'text') {
						question = new Text(q.id);
						question.text = q.name;
						question.description = q.description;
						question.option_0 = q.option_0;
						question.is_required = q.is_required;
						question.picture = q.image;
						question.pictureOrientation = q.image_align;
						question.pictureDescription = q.image_title;
					} else if (q.type === 'information') {
						question = new Text(q.id);
						question.text = q.name;
						question.description = q.description;
						question.option_0 = q.option_0;
						question.type = 'information';
						question.picture = q.image;
						question.pictureOrientation = q.image_align;
						question.pictureDescription = q.image_title;
					} else if (q.type === 'radio') {
						question = new Option(q.id);
						question.text = q.name;
						question.description = q.description;
						question.option_0 = q.option_0;
						question.option_1 = q.option_1;
						question.option_2 = q.option_2;
						question.is_regression = q.is_regression;
						question.is_regression_target = q.is_regression_target;
						question.is_required = q.is_required;
						question.picture = q.image;
						question.pictureOrientation = q.image_align;
						question.pictureDescription = q.image_title;
						question.personality_active = q.personality_active;
						q.answers.forEach(a => {
							let answer = new Answer(a.id);
							answer.text = a.name;
							answer.points = a.points;

							if (a.follow_question_id != null) {
								answer.follow = a.follow_question_id;
							} else if (a.follow_topic_id != null) {
								answer.follow = a.follow_topic_id;
							} else if (a.end_survey === true) {
								answer.follow = '#ENDOFSURVEY#' + topic.id;
							}

							question.answers.push(answer);
						});
					} else if (q.type === 'checkbox') {
						question = new Option(q.id);
						question.text = q.name;
						question.description = q.description;
						question.type = 'checkbox';
						question.option_0 = q.option_0;
						question.option_1 = q.option_1;
						question.option_2 = q.option_2;
						question.is_required = q.is_required;
						question.picture = q.image;
						question.pictureOrientation = q.image_align;
						question.pictureDescription = q.image_title;
						question.personality_active = q.personality_active;
						q.answers.forEach(a => {
							let answer = new Answer(a.id);
							answer.text = a.name;
							answer.points = a.points;
							question.answers.push(answer);
						});
					} else if (q.type === 'smileys') {
						question = new Option(q.id);
						question.text = q.name;
						question.description = q.description;
						question.type = 'smileys';
						question.is_regression = q.is_regression;
						question.is_regression_target = q.is_regression_target;
						question.is_required = q.is_required;
						question.picture = q.image;
						question.pictureOrientation = q.image_align;
						question.pictureDescription = q.image_title;
						question.personality_active = q.personality_active;
						question.option_2 = q.option_2;
						q.answers.forEach(a => {
							let answer = new Answer(a.id);
							answer.text = a.name;
							answer.points = a.points;

							if (a.follow_question_id != null) {
								answer.follow = a.follow_question_id;
							} else if (a.follow_topic_id != null) {
								answer.follow = a.follow_topic_id;
							} else if (a.end_survey === true) {
								answer.follow = '#ENDOFSURVEY#' + topic.id;
							}

							question.answers.push(answer);
						});
					} else if (q.type === 'contact') {
						question = new Contact(q.id);
						question.text = q.name;
						question.description = q.description;
						question.option_0 = q.option_0;
						question.option_2 = q.option_2;
						question.picture = q.image;
						question.pictureOrientation = q.image_align;
						question.pictureDescription = q.image_title;

						if (question.option_2 == null) {
							question.option_2 = [new ContactField(uuidv4(), '', 'text', false)];
						}
					} else if (q.type === 'matrix') {
						if (matrixQuestion === null) {
							matrixQuestion = new Matrix(q.id);
							matrixQuestion.text = q.option_1;
							matrixQuestion.description = q.description;
							matrixQuestion.is_regression = q.is_regression;
							matrixQuestion.is_required = q.is_required;
							matrixQuestion.personality_active = q.personality_active;

							let options = q.option_0.split(';');
							options.forEach(o => {
								let matrixOption = new MatrixOption();
								matrixOption.text = o;
								matrixQuestion.options.push(matrixOption);
							});
							matrixQuestionText = matrixQuestion.text;
						}

						let question = new Option(q.id);
						question.text = q.name;
						question.description = q.description;
						question.is_regression = q.is_regression;

						q.answers.forEach(a => {
							let answer = new Answer(a.id);
							answer.text = a.name;

							if (a.follow_question_id != null) {
								answer.follow = a.follow_question_id;
							} else if (a.follow_topic_id != null) {
								answer.follow = a.follow_topic_id;
							} else if (a.end_survey === true) {
								answer.follow = '#ENDOFSURVEY#' + topic.id;
							}

							question.answers.push(answer);
						});

						matrixQuestion.questions.push(question);
					}

					if (question != null) {
						topic.questions.push(question);
					}
				});

				if (matrixQuestion != null) {
					topic.questions.push(matrixQuestion);
					matrixQuestion = null;
					matrixQuestionText = null;
				}

				that.survey.topics.push(topic);
			});

			this.isLoading = false;
		},
		calcFollowOptions: function() {
			let arr = [];
			let newTopics = [...this.survey.topics];
			newTopics.forEach(t => {
				let arrQ = [];

				t.questions.forEach(q => {
					arrQ.push({ id: q.id, label: q.text });
				});
				arrQ.push({ id: '#ENDOFSURVEY#' + t.id, label: this.$t('VIEWS.Survey.Create.General.endOfSurvey') });

				arr.push({ id: 't' + t.id, label: t.name, children: arrQ });
			});
			this.followOptions = arr;
		},
		addTopic: function() {
			let newTopic = new Topic(uuidv4());
			this.survey.translation.forEach(translation => {
				let topicTranslation = new TranslationTopic();
				topicTranslation.code = translation.code;
				newTopic.translation.push(topicTranslation);
			});

			this.survey.topics.push(newTopic);
		},
		copyTopic: function(t) {
			let copied = klona(t);
			copied.id = uuidv4();
			this.changeIdRecursive(copied);
			this.topics.push(copied);
			this.calcFollowOptions();
		},
		deleteTopic: function(t) {
			this.topics = this.topics.filter(function(value) {
				return value.id !== t.id;
			});
			this.calcFollowOptions();
		},
		moveUp: function(t) {
			let index = this.survey.topics.indexOf(t);
			let length = this.survey.topics.length;

			if (index - 1 >= length) {
				let k = index - 1 - length + 1;
				while (k--) {
					this.survey.topics.push(undefined);
				}
			}
			this.survey.topics.splice(index - 1, 0, this.survey.topics.splice(index, 1)[0]);

			this.calcFollowOptions();
		},
		moveDown: function(t) {
			let index = this.survey.topics.indexOf(t);
			let length = this.survey.topics.length;

			if (index + 1 >= length) {
				let k = index + 1 - length + 1;
				while (k--) {
					this.survey.topics.push(undefined);
				}
			}
			this.survey.topics.splice(index + 1, 0, this.survey.topics.splice(index, 1)[0]);

			this.calcFollowOptions();
		},
		changeIdRecursive: function(obj) {
			Object.keys(obj).forEach(key => {
				if (key === 'id') {
					obj[key] = uuidv4();
				}
				if (Array.isArray(obj[key])) {
					obj[key].forEach(arr => {
						this.changeIdRecursive(arr);
					});
				}
			});
		},
		saveSurvey: function() {
			// this.$v.$touch();
			// if (this.$v.$invalid) {
			// 	PRINOR_TOASTS.general.error(this, { code: 'S-23' });
			// 	PRINOR_PROCESSES.reset();
			// } else {
			// 	PRINOR_PROCESSES.isUpdating();
			// 	this.save().then(data => {
			// 		if (!('id' in this.$route.params)) {
			// 			this.$router.push({ name: 'survey-edit', params: { id: data.data.id } });
			// 		}
			// 		this.get().then(data => {
			// 			this.createData(data.data.survey);
			// 			this.createTranslation(data.data.translation);
			// 			this.unsavedChanges = false;
			// 		});
			// 	});
			// }
			PRINOR_PROCESSES.isUpdating();
			this.save().then(data => {
				if (!('id' in this.$route.params)) {
					this.$router.push({ name: 'survey-edit', params: { id: data.data.id } });
				}
				this.get().then(data => {
					this.createData(data.data.survey);
					this.createTranslation(data.data.translation);
					this.unsavedChanges = false;
				});
			});
		},
		get: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('survey/edit/' + this.$route.params.id).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === false) {
							PRINOR_TOASTS.general.error(this, data.data.error);
						} else {
							resolve(data.data);
						}
					}
				});
			});
		},
		save: function() {
			let url = 'create';
			if ('id' in this.$route.params) {
				url = this.$route.params.id;
			}
			else {
				this.unsavedChanges = false;
			}

			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.post('survey/edit/' + url, this.survey).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						PRINOR_TOASTS.save.confirmation(this);
						resolve(data.data);
					}
					else {
						PRINOR_TOASTS.save.error(this, data.data.error);
					}
				});
			});
		},
		setRegressionTarget: function(event) {
			if (event.value === 1) {
				this.survey.topics.forEach(t => {
					t.questions.forEach(q => {
						if (q.type === 'radio' || q.type === 'smileys' || q.type === 'matrix' || q.type === 'nps') {
							if (q.id === event.id) {
								q.is_regression_target = 1;
							} else {
								q.is_regression_target = 0;
							}
						}
					});
				});
			}
		},
	},
	beforeRouteLeave (to, from, next) {
		if (this.unsavedChanges) {
			Swal.fire({
				title: this.$t('GENERAL.General.leave.title'),
				text: this.$t('GENERAL.General.leave.text'),
				icon: 'warning',
				confirmButtonText: this.$t('GENERAL.General.yes'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.General.no'),
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary',
				},
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					return next();
				}
				else if (result.isDismissed) {
					return next(false);
				}
			});
		}
		else {
			return next();
		}
	}
};
</script>

<style scoped>
#kt_content.survey-edit-content {
	padding-top: 0 !important;
}
#kt_content.survey-edit-content > .flex-column-fluid > .container-fluid {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
#kt_content.survey-edit-content #kt_subheader {
	-webkit-box-shadow: none;
	box-shadow: none;
}

::v-deep ul.nav-tabs {
	border-top: 1px solid #E4E6EF;
}
::v-deep ul.nav-tabs > li.nav-item {
	margin-right: 0 !important;
}
::v-deep ul.nav-tabs .nav-link {
	border-radius: 0 !important;
	border: none !important;
	color: #000;
	background-color: #fff !important;
	border-left: 1px solid rgb(235, 237, 243) !important;
	border-right: 1px solid rgb(235, 237, 243) !important;
}
::v-deep ul.nav-tabs .nav-link.active {
	background-color: #339AC3 !important;
	color: #fff;
}
</style>
<style scoped>
textarea.form-control {
	resize: none;
}
hr.topic-splitter {
	border-top: 3px dashed gray;
}

#sidebar-toc {
	position: fixed;
	display: block;
	z-index: 1000;
	overflow-y: auto;
	height: calc(100vh - 200px);
	background-color: #ffffff;
	border-radius: 0.42rem;
}
#sidebar-toc ul {
	list-style-type: none;
	padding-inline-start: 0;
}
#sidebar-toc > ul > li > span {
	font-weight: bold;
}
</style>
