<template>
	<div v-frag>
		<tr>
			<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
				<div class="ml-6">
					<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.name')">
						<b-input-group>
							<template #prepend>
								<b-input-group-text
									><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.matrix') }}</strong></b-input-group-text
								>
							</template>
							<b-form-input type="text" disabled :value="question.text"></b-form-input>
						</b-input-group>
					</b-form-group>
				</div>
			</th>
			<td v-for="(translation, qii) in question.translation" :key="'topic-' + ti + '-question-' + index + '-name-' + qii" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
				<div class="ml-6">
					<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.name')">
						<b-input-group>
							<template #prepend>
								<b-input-group-text
									><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.matrix') }}</strong></b-input-group-text
								>
							</template>
							<b-form-input type="text" v-model="question.translation[qii].text"></b-form-input>
						</b-input-group>
					</b-form-group>
				</div>
			</td>
		</tr>
		<tr>
			<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
				<div class="ml-6">
					<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.description')">
						<b-input-group>
							<template #prepend>
								<b-input-group-text
								><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.matrix') }}</strong></b-input-group-text
								>
							</template>
							<b-form-textarea rows="3" readonly no-resize :value="question.description"></b-form-textarea>
						</b-input-group>
					</b-form-group>
				</div>
			</th>
			<td v-for="(translation, qii) in question.translation" :key="'topic-' + ti + '-question-' + index + '-description-' + qii" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
				<div class="ml-6">
					<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.description')">
						<b-input-group>
							<template #prepend>
								<b-input-group-text
								><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.matrix') }}</strong></b-input-group-text
								>
							</template>
							<b-form-textarea rows="3" no-resize v-model="question.translation[qii].description"></b-form-textarea>
						</b-input-group>
					</b-form-group>
				</div>
			</td>
		</tr>
		<template v-for="(option, oi) in question.options">
			<tr :key="'topic-' + ti + '-question-' + index + '-option-' + oi">
				<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div class="ml-9">
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.Language.options')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text
									><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.option') }}</strong></b-input-group-text
									>
								</template>
								<b-form-input type="text" disabled :value="option.text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</th>
				<td v-for="(translation, oii) in option.translation" :key="'topic-' + ti + '-question-' + index + '-option-' + oii + '-text'" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div class="ml-9">
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.Language.options')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text
									><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.option') }}</strong></b-input-group-text
									>
								</template>
								<b-form-input type="text" v-model="option.translation[oii].text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</td>
			</tr>
		</template>
		<template v-for="(question, qi) in question.questions">
			<tr :key="'topic-' + ti + '-question-' + index + '-question-' + qi">
				<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div class="ml-12">
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.name')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text
									><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.question') }}</strong></b-input-group-text
									>
								</template>
								<b-form-input type="text" disabled :value="question.text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</th>
				<td v-for="(translation, qii) in question.translation" :key="'topic-' + ti + '-question-' + index + '-question-' + qii + '-text'" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div class="ml-12">
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.name')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text
									><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.question') }}</strong></b-input-group-text
									>
								</template>
								<b-form-input type="text" v-model="question.translation[qii].text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</td>
			</tr>
		</template>
	</div>
</template>

<script>
export default {
	name: 'MatrixLanguage',
	props: {
		question: Object,
		index: Number,
		ti: Number,
	},
	data: function() {
		return {
			columnWidth: '400px',
		}
	},
	mounted() {
		let contentDiv = document.getElementById('kt_content');
		if (contentDiv != null) {
			let width = contentDiv.clientWidth;
			this.columnWidth =  width / 3 + 'px';
		}
	}
};
</script>

<style scoped></style>
