<template>
	<div ref="tablecontainer" id="tableContainer" class="table-wrapper" :style="{ height: 'calc(85vh - ' + tableWrapperHeight + ')' }">
		<table class="table table-bordered" style="width: max-content">
			<thead>
			<tr>
				<th :style="{ width: 'calc(' + columnWidth + ' - 2rem)' }">
					<b-form-select :options="languages" v-model="survey.language" text-field="name" value-field="code"></b-form-select>
				</th>
				<td v-for="(translation, i) in survey.translation" :key="'language-' + i" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-input-group>
							<b-form-select :options="languages" v-model="translation.code" text-field="name" value-field="code" @change="$emit('changeLanguage', $event, i)"></b-form-select>
							<template #append>
								<b-input-group-text class="p-0 zindex-0">
									<a href="" v-on:click.prevent="$emit('deleteTranslation', i)" class="btn btn-icon btn-light btn-sm my-auto">
										<i class="fas fa-trash text-primary"></i>
									</a>
								</b-input-group-text>
							</template>
						</b-input-group>
					</div>
				</td>
				<td style="width: 0px;">
					<a href="" v-on:click.prevent="$emit('addTranslation')" class="btn btn-icon btn-light btn-sm mx-1 my-auto mr-5">
						<i class="fas fa-plus text-primary"></i>
					</a>
				</td>
			</tr>
			</thead>
			<tbody>
			<tr>
				<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.name')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" disabled :value="survey.name"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</th>
				<td v-for="(translation, index) in survey.translation" :key="'survey-name-' + index" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.name')">
						<b-input-group>
							<template #prepend>
								<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
							</template>
							<b-form-input type="text" v-model="survey.translation[index].name"></b-form-input>
						</b-input-group>
					</b-form-group>
				</td>
			</tr>
			<tr>
				<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.title')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" disabled :value="survey.title"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</th>
				<td v-for="(translation, index) in survey.translation" :key="'survey-title-' + index" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.title')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" v-model="survey.translation[index].title"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</td>
			</tr>
			<tr>
				<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.subtitle')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" disabled :value="survey.sub_title"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</th>
				<td v-for="(translation, index) in survey.translation" :key="'survey-subtitle-' + index" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.subtitle')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" v-model="survey.translation[index].sub_title"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</td>
			</tr>
			<tr>
				<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.description')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-textarea readonly rows="3" no-resize :value="survey.description"></b-form-textarea>
							</b-input-group>
						</b-form-group>
					</div>
				</th>
				<td v-for="(translation, index) in survey.translation" :key="'survey-description-' + index" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.description')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-textarea rows="3" no-resize v-model="survey.translation[index].description"></b-form-textarea>
							</b-input-group>
						</b-form-group>
					</div>
				</td>
			</tr>




			<tr>
				<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.startSurveyButton')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" disabled :value="survey.start_text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</th>
				<td v-for="(translation, index) in survey.translation" :key="'survey-description-' + index" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.startSurveyButton')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" v-model="survey.translation[index].start_text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</td>
			</tr>
			<tr>
				<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.backButton')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" disabled :value="survey.previous_text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</th>
				<td v-for="(translation, index) in survey.translation" :key="'survey-description-' + index" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.backButton')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" v-model="survey.translation[index].previous_text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</td>
			</tr>
			<tr>
				<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.nextButton')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" disabled :value="survey.next_text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</th>
				<td v-for="(translation, index) in survey.translation" :key="'survey-description-' + index" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.nextButton')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" v-model="survey.translation[index].next_text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</td>
			</tr>
			<tr>
				<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.othersTerm')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" disabled :value="survey.others_text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</th>
				<td v-for="(translation, index) in survey.translation" :key="'survey-description-' + index" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.othersTerm')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" v-model="survey.translation[index].others_text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</td>
			</tr>
			<tr>
				<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.noAnswerTerm')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" disabled :value="survey.no_answer_text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</th>
				<td v-for="(translation, index) in survey.translation" :key="'survey-description-' + index" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.noAnswerTerm')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" v-model="survey.translation[index].no_answer_text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</td>
			</tr>
			<tr>
				<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.finishedHeaderTerm')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" disabled :value="survey.finished_header_text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</th>
				<td v-for="(translation, index) in survey.translation" :key="'survey-description-' + index" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.finishedHeaderTerm')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" v-model="survey.translation[index].finished_header_text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</td>
			</tr>
			<tr>
				<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.finishedBodyTerm')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" disabled :value="survey.finished_body_text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</th>
				<td v-for="(translation, index) in survey.translation" :key="'survey-description-' + index" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
					<div>
						<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.finishedBodyTerm')">
							<b-input-group>
								<template #prepend>
									<b-input-group-text><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.survey') }}</strong></b-input-group-text>
								</template>
								<b-form-input type="text" v-model="survey.translation[index].finished_body_text"></b-form-input>
							</b-input-group>
						</b-form-group>
					</div>
				</td>
			</tr>





			<template v-for="(topic, ti) in survey.topics">
				<TopicLanguage :topic="topic" :index="ti" :key="'survey-topic-' + ti"></TopicLanguage>
			</template>
			</tbody>
		</table>
	</div>
</template>

<script>
import * as languageData from '@/core/config/languages.json';
import TopicLanguage from '@/view/component/survey/language/TopicLanguage';

export default {
	name: 'SurveyLanguage',
	components: {TopicLanguage},
	props: {
		survey: Object,
	},
	computed: {
		tableWrapperHeight() {
			let ktContent = document.getElementById('kt_content');
			if (ktContent != null) {
				let contentContainer = ktContent.getBoundingClientRect();
				return contentContainer.top + 'px';
			}

			return '100px';
		},
	},
	data: function() {
		return {
			languages: [],
			columnWidth: '400px',
		};
	},
	mounted() {
		this.languages = languageData.default;

		let contentDiv = document.getElementById('kt_content');
		if (contentDiv != null) {
			let width = contentDiv.clientWidth;
			this.columnWidth =  width / 3 + 'px';
		}

		// let contentContainer = document.getElementById('kt_content').getBoundingClientRect();
		let container = document.getElementById("tableContainer")
		if (container != null) {
			let ths = container.getElementsByTagName('th');
			ths.forEach(th => {
				th.style.width = 'calc(' + this.columnWidth + ' - 2rem)';
				// th.style['margin-left'] = -contentContainer.left + 'px';
				th.style.left = -this.columnWidth;
			});
		}


	},
};
</script>

<style scoped>
::v-deep .no-form-margin {
	margin-bottom: 0 !important;
}
.table-wrapper {
	/*background-color: white;*/
	overflow: auto;
}
.table-wrapper table {
	table-layout: fixed;
}
.table-wrapper table th,
.table-wrapper table ::v-deep th {
	position: -webkit-sticky; /* for Safari */
	position: sticky;
	left: 0;
	z-index: 1;
	background-color: white;
}
.table-wrapper table tr {
	display: table-row;
}

::v-deep th,
::v-deep td {
	display: inline-block;
}
</style>
