import General from "@/data/survey/type/General";

class Contact extends General {
	constructor(id) {
		super(id);
		this.type = 'contact';
		this.option_0 = null;
		this.option_2 = [];
		this.is_required = false;
	}
}

class ContactField {
	constructor(id, name, type, required) {
		this.id = id;
		this.name = name;
		this.type = type;
		this.required = required;
		this.value = '';
		this.association = '';
		this.translation = [];
	}
}

export { Contact, ContactField };
