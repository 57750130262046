import General from "@/data/survey/type/General";

class Nps extends General {
	constructor(id) {
		super(id);
		this.type = 'nps';
		this.is_regression = 0;
		this.is_regression_target = 0;
		this.condition = [];
		this.is_required = false;
		this.personality_active = false;
		this.option_2 = {
			is_smiley: false,
		};
	}

	loadConditions(obj) {
		if (obj.option_0.indexOf(':') !== -1) {
			if (obj.option_0.indexOf(';') !== -1) {
				let fromToArr = obj.option_0.split(';');
				let followArr = obj.option_1.split(';');

				for (let i = 0; i < fromToArr.length; i++) {
					let from = fromToArr[i].split(':')[0];
					let to = fromToArr[i].split(':')[1];

					this.condition.push({
						from: from,
						to: to,
						follow: followArr[i],
					});
				}
			} else {
				let from = obj.option_0.split(':')[0];
				let to = obj.option_0.split(':')[1];

				this.condition.push({
					from: from,
					to: to,
					follow: obj.option_1,
				});
			}
		}
	}
}

export default Nps;
