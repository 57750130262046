<template>
	<div class="col-12 px-10 pt-10 my-5 question-container" :id="question.id">
		<div class="question-index d-flex">
			<span class="mx-auto my-auto">{{ index + 1 }}</span>
		</div>
		<div class="question-type d-flex px-2">
			<span class="mx-auto my-auto">
				<template v-if="type === 'radio'">{{ $t('VIEWS.Survey.Create.Question.Multi.single') }}</template>
				<template v-else-if="type === 'checkbox'">{{ $t('VIEWS.Survey.Create.Question.Multi.multi') }}</template>
				<template v-else-if="type === 'smileys'">{{ $t('VIEWS.Survey.Create.Question.Multi.smiley') }}</template>
				<template v-else-if="type === 'nps'">{{ $t('VIEWS.Survey.Create.Topic.recommendation') }}</template>
				<template v-else-if="type === 'matrix'">{{ $t('VIEWS.Survey.Create.Topic.matrix') }}</template>
				<template v-else-if="type === 'text'">{{ $t('VIEWS.Survey.Create.Question.Text.text') }}</template>
				<template v-else-if="type === 'information'">{{ $t('VIEWS.Survey.Create.Question.Text.information') }}</template>
				<template v-else-if="type === 'contact'">{{ $t('VIEWS.Survey.Create.Topic.contact') }}</template>
			</span>
		</div>
		<div class="question-draggable d-flex">
			<i class="mx-auto fas fa-grip-lines"></i>
		</div>
		<div class="question-tools d-flex">
			<a href="" class="px-2 d-flex" v-on:click.prevent="$emit('moveUp', question)" v-if="index > 0">
				<i class="fas fa-angle-up" style="vertical-align: middle; font-size: 2rem; text-align: center;"></i>
			</a>
			<a href="" class="px-2 d-flex" v-on:click.prevent="$emit('moveDown', question)" v-if="index < amount">
				<i class="fas fa-angle-down" style="vertical-align: middle; font-size: 2rem; text-align: center;"></i>
			</a>
			<a href="" class="px-2 d-flex" v-on:click.prevent="$emit('copyQuestion', question)">
				<i class="my-auto mx-auto fas fa-copy"></i>
			</a>
			<a href="" class="px-2 d-flex" v-on:click.prevent="$emit('deleteQuestion', question)">
				<i class="my-auto mx-auto fas fa-trash-alt"></i>
			</a>
		</div>
		<div class="d-flex mt-5">
			<h3 class="card-title w-100 d-inline-block">
				<b-form-input
					v-model="question.text"
					name="text"
					type="text"
					:placeholder="$t('VIEWS.Survey.Create.Question.inputQuestion')"
					v-on:focusout="$emit('refresh')"
					:id="'question-' + question.id + '-text'"
					ref="text"
				></b-form-input>
				<b-popover :target="'question-' + question.id + '-text'" triggers="focus" placement="top" v-if="personalised === true">
					<h6>{{ $t('VIEWS.Survey.Create.Placeholder.title') }}</h6>
					<template v-for="(name, value) in personalPlaceholder">
						<b-button v-if="value !== 'that'" variant="primary" class="m-2" v-on:click.prevent="personalPlaceholder.insertPlaceholder( name, 'question', 'text')" :key="'question-' + question.id + '-text-' + value">{{ $t('VIEWS.Survey.Create.Placeholder.' + value) }}</b-button>
					</template>
				</b-popover>
			</h3>
		</div>

		<div class="mb-5">
			<b-tabs content-class="mt-0" justified>
				<b-tab class="border-left border-right border-bottom qcard p-7" :title="$t('VIEWS.Administration.Right.Edit.General.title')" active>
					<slot ref="slotQuestion"></slot>
				</b-tab>
				<b-tab class="border-left border-right border-bottom qcard p-7" :title="$t('VIEWS.Survey.Create.Question.tabOptions')" v-if="type !== 'contact'">
					<div class="d-flex mt-5">
						<div class="w-100" v-if="question.type !== 'contact'">
							<b-form-group :label="$t('VIEWS.Survey.Create.Question.isRequired')">
								<b-form-radio-group v-model="question.is_required" :options="options" buttons button-variant="outline-primary" size="sm"></b-form-radio-group>
							</b-form-group>
						</div>
					</div>
					<div class="w-100" v-if="type === 'radio' || type === 'checkbox' || type === 'smileys'">
						<b-form-group :label="$t('VIEWS.Survey.Create.Question.Multi.label')">
							<b-form-radio-group
								v-model="question.type"
								:options="[
						{ text: $t('VIEWS.Survey.Create.Question.Multi.single'), value: 'radio' },
						{ text: $t('VIEWS.Survey.Create.Question.Multi.multi'), value: 'checkbox' },
						{ text: $t('VIEWS.Survey.Create.Question.Multi.smiley'), value: 'smileys' },
					]"
								buttons
								button-variant="outline-primary"
								size="sm"
							></b-form-radio-group>
						</b-form-group>
					</div>
					<div class="w-100" v-if="question.type === 'text' || question.type === 'information'">
						<b-form-group :label="$t('VIEWS.Survey.Create.Question.Text.label')">
							<b-form-radio-group v-model="question.type" :options="[
							{
								text: this.$t('VIEWS.Survey.Create.Question.Text.text'),
								value: 'text',
							},
							{
								text: this.$t('VIEWS.Survey.Create.Question.Text.information'),
								value: 'information',
							},
						]" buttons button-variant="outline-primary" size="sm"></b-form-radio-group>
						</b-form-group>
					</div>
					<div class="w-100" v-if="type !== 'checkbox' && type !== 'contact' && type !== 'text' && type !== 'information'">
						<b-form-group :label="$t('VIEWS.Survey.Create.Question.regression')">
							<b-form-radio-group
								v-model="question.is_regression"
								:options="[
						{ text: $t('GENERAL.General.yes'), value: true },
						{ text: $t('GENERAL.General.no'), value: false },
					]"
								buttons
								button-variant="outline-primary"
								size="sm"
							></b-form-radio-group>
						</b-form-group>
					</div>
					<div class="w-100" v-if="question.is_regression === true && (type === 'radio' || type === 'nps')">
						<b-form-group :label="$t('VIEWS.Survey.Create.Question.regressionTarget')">
							<b-form-radio-group
								v-model="question.is_regression_target"
								:options="[
						{ text: $t('GENERAL.General.yes'), value: true },
						{ text: $t('GENERAL.General.no'), value: false },
					]"
								buttons
								button-variant="outline-primary"
								size="sm"
								@change="$emit('setRegressionTarget', { value: $event, id: question.id })"
							></b-form-radio-group>
						</b-form-group>
					</div>
					<div class="w-100" v-if="type === 'radio' || type === 'nps' || type === 'checkbox' || type === 'smileys'">
						<b-form-group :label="$t('VIEWS.Survey.Create.Question.personality')">
							<b-form-radio-group
								v-model="question.option_2.personality_active"
								:options="[
						{ text: $t('GENERAL.General.yes'), value: true },
						{ text: $t('GENERAL.General.no'), value: false },
					]"
								buttons
								button-variant="outline-primary"
								size="sm"
							></b-form-radio-group>
						</b-form-group>
					</div>
					<div class="w-100" v-if="type === 'radio' || type === 'checkbox' || type === 'smileys'">
						<b-form-group :label="$t('VIEWS.Survey.Create.Question.pointsSystem')">
							<b-form-radio-group
								v-model="question.option_2.point_system_active"
								:options="[
						{ text: $t('GENERAL.General.yes'), value: true },
						{ text: $t('GENERAL.General.no'), value: false },
					]"
								buttons
								button-variant="outline-primary"
								size="sm"
							></b-form-radio-group>
						</b-form-group>
					</div>
					<div class="w-100" v-if="type === 'nps'">
						<b-form-group :label="'##Smileys anzeigen'">
							<b-form-radio-group
								v-model="question.option_2.is_smiley"
								:options="[
						{ text: $t('GENERAL.General.yes'), value: true },
						{ text: $t('GENERAL.General.no'), value: false },
					]"
								buttons
								button-variant="outline-primary"
								size="sm"
							></b-form-radio-group>
						</b-form-group>
					</div>
				</b-tab>
				<b-tab class="border-left border-right border-bottom qcard p-3" :title="$t('VIEWS.Survey.Create.Question.tabPicture')" v-if="type !== 'matrix'">
					<div class="mt-5 row mx-5">
						<div class="col-12 col-md-6 mb-0">
							<div class="w-100 border picContainer align-items-center d-flex flex-column" style="height: 20vh;" :style="backgroundPicCss">
								<b-button v-on:click="deletePicture" v-if="backgroundPicCss !== null" variant="danger" class="position-absolute" style="align-self: baseline; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px; border-top-right-radius: 0px;">
									<i class="my-auto mx-auto fas fa-trash-alt"></i>
								</b-button>
								<span v-if="backgroundPicCss === null" class="mx-auto my-auto position-absolute empty-chart-text">{{ $t('VIEWS.Survey.Create.Question.picturePreview') }}</span>
							</div>
						</div>

						<div class="col-12 col-md-6 mb-0">
							<div class="row">
								<b-form-group class="col-12 col-md-12 mb-0">
									<b-form-file
										placeholder="Wählen Sie ein Bild aus.."
										drop-placeholder="Bild hier ablegen..."
										@change="pictureChange"
									></b-form-file>
									<span class="form-text text-muted">Bitte wählen Sie ein Bild aus, das unter der Frage angezeigt werden soll.</span>
								</b-form-group>
							</div>
							<div class="row mt-5">
								<b-form-group class="col-12 col-md-12 mb-0">
									<b-form-radio-group v-model="question.pictureOrientation" buttons button-variant="outline-primary">
										<b-form-radio value="l"><i class="fas fa-align-left"></i></b-form-radio>
										<b-form-radio value="c"><i class="fas fa-align-center"></i></b-form-radio>
										<b-form-radio value="r"><i class="fas fa-align-right"></i></b-form-radio>
									</b-form-radio-group>
									<span class="form-text text-muted">Die Position des Bildes festlegen.</span>
								</b-form-group>
							</div>
							<div class="row mt-5">
								<b-form-group class="col-12 col-md-12 mb-0">
								</b-form-group>
							</div>
						</div>
					</div>
					<div class="row mt-5 mx-5">
						<b-form-group class="col-12 col-md-12">
							<label>{{ $t('VIEWS.Survey.Create.Question.pictureDescription') }}</label>
							<b-textarea v-model="question.pictureDescription"></b-textarea>
						</b-form-group>
					</div>
				</b-tab>
			</b-tabs>
		</div>

	</div>
</template>

<script>
// import {validationMixin} from "vuelidate";
// import {required} from "vuelidate/lib/validators";
import Placeholder from "@/data/survey/Placeholder";

export default {
	name: 'QuestionContainer',
	props: {
		question: Object,
		index: Number,
		type: String,
		amount: Number,
		personalised: Boolean,
	},
	mounted() {
		if (this.type === 'radio' || this.type === 'nps' || this.type === 'checkbox' || this.type === 'smileys') {
			if (this.question.option_2 == null) {
				this.question.option_2 = {
					personality_active: false,
				};
			}

			if (!('personality_active' in this.question.option_2)) {
				this.question.option_2.personality_active = false;
			}
		}

		let that = this;
		this.fileReader.onloadend = function () {
			let b64 = that.fileReader.result.replace(/^data:.+;base64,/, '');
			that.question.picture = b64;
		};

		if (this.question.picture != null) {
			this.pictureFrontend = this.storageUrl + '/questionPicture/' + this.question.picture;
		}
	},
	computed: {
		personalPlaceholder: function() {
			return new Placeholder(this);
		},
		storageUrl: function() {
			return process.env.VUE_APP_URL_STORAGE;
		},
		backgroundPicCss: function() {
			if (this.question.picture !== '' && this.question.picture != null) {
				return {
					'background-image': 'url(' + this.pictureFrontend + ')',
					'background-size': 'contain',
					'background-repeat': 'no-repeat',
					'background-position': 'center',
				};
			}
			else {
				return null;
			}
		},
	},
	data: function() {
		return {
			options: [
				{
					text: this.$t('GENERAL.General.yes'),
					value: true,
				},
				{
					text: this.$t('GENERAL.General.no'),
					value: false,
				},
			],
			pictureFrontend: null,
			fileReader: new FileReader(),
		};
	},
	// mixins: [validationMixin],
	// validations: {
	// 	question: {
	// 		text: {
	// 			required,
	// 		},
	// 	},
	// },
	methods: {
		pictureChange: function(event) {
			let file = event.target.files[0];
			this.fileReader.readAsDataURL(file);
			this.pictureFrontend = URL.createObjectURL(file);
		},
		deletePicture: function() {
			this.question.picture = null;
			this.question.pictureDescription = '';
			this.question.pictureOrientation = 'l';
		}
	}
};
</script>

<style scoped>
.qcard {
	border-bottom-left-radius: 0.42rem;
	border-bottom-right-radius: 0.42rem;
}
.picContainer {
	border-radius: 0.42rem;
}
h3.card-title > input {
	font-size: 1.275rem;
	font-weight: 600 !important;
	color: #181c32 !important;
	width: 100%;
}
div.question-container {
	border: 1px solid #d4d4d4;
	border-radius: 5px;
}
div.question-index {
	position: absolute;
	height: 2.5rem;
	width: 2.5rem;
	left: 0;
	top: 0;
	font-size: 1.4rem;
	line-height: 1rem;
	border-top-left-radius: 5px;
	background-color: #1e1e2d;
	color: #fff;
}
div.question-type {
	position: absolute;
	height: 2.5rem;
	left: 2.5rem;
	top: 0;
	font-size: 1.4rem;
	line-height: 1rem;
	border-left: 1px solid #c1c1c1;
	background-color: #1e1e2d;
	color: #fff;
}
div.question-draggable {
	position: absolute;
	height: 2.5rem;
	width: 2.5rem;
	left: calc(50% - 1.5rem);
	top: 0;
}
div.question-draggable i {
	font-size: 1.8rem;
}
div.question-tools {
	position: absolute;
	height: 2.5rem;
	right: 0;
	top: 0;
	background-color: #1e1e2d;
	border-top-right-radius: 5px;
	color: #fff;
}
div.question-tools > a {
	height: 100%;
	width: 2.5rem;
}
div.question-tools > a:hover {
	background-color: #7f7f7f;
}
div.question-tools > a:hover:last-of-type {
	border-top-right-radius: 5px;
}
div.question-tools > a > i {
	font-size: 1.5rem;
	color: #fff;
}
.empty-chart-text {
	top: calc(50% - 1.5rem);
	font-size: 3rem;
	text-align: center;
	color: darkgray;
}
</style>
