class TranslationQuestion {
	constructor() {
		this.code = '';
		this.text = '';
		this.description = '';
		this.option_0 = '';
		this.option_1 = '';
	}
}

export default TranslationQuestion;
