class TranslationTopic {
	constructor() {
		this.code = '';
		this.name = '';
		this.title = '';
		this.description = '';
	}
}

export default TranslationTopic;
