<template>
	<div>
		<div class="">
			<h5 class="mb-4">{{ $t('VIEWS.Survey.Create.Question.answers') }}</h5>
			<b-form-group :label="$t('VIEWS.Survey.Create.Question.addOthers')" v-if="question.type !== 'smileys'">
				<b-form-radio-group
					v-model="question.option_0"
					:options="[
						{ text: $t('GENERAL.General.yes'), value: 1 },
						{ text: $t('GENERAL.General.no'), value: 0 },
					]"
					buttons
					button-variant="outline-primary"
					size="sm"
				></b-form-radio-group>
			</b-form-group>
			<b-form-group :label="$t('VIEWS.Survey.Create.Question.addNoAnswer')" v-if="question.type !== 'smileys'">
				<b-form-radio-group
					v-model="question.option_1"
					:options="[
						{ text: $t('GENERAL.General.yes'), value: 1 },
						{ text: $t('GENERAL.General.no'), value: 0 },
					]"
					buttons
					button-variant="outline-primary"
					size="sm"
				></b-form-radio-group>
			</b-form-group>
			<draggable tag="div" class="row" v-model="question.answers" :group="'answers-' + question.id">
				<template v-for="(answer, ai) in question.answers">
					<div class="col-12 row container" :key="'a1' + index + '-' + ai">
						<div :class="question.type === 'checkbox' ? ((question.option_2.point_system_active === true) ? 'col-9' : 'col-11') : 'col-5'" class="pl-0">
							<div class="form-group" v-if="question.type !== 'smileys'">
								<input
									class="form-control"
									type="text"
									name="text"
									v-model="answer.text"
									:placeholder="$t('VIEWS.Survey.Create.Question.answer') + ' ' + (ai + 1)"
									v-on:input="addAnswerInput($event, ai)"
									v-on:focusin="addAnswer(ai)"
								/>
							</div>
							<div class="smileys" v-if="question.type === 'smileys'">
								<div class="h1 ml-5" v-if="ai === 0">
									<img :src="baseUrl + '/media/smileys/face_with_steam_from_nose.gif'" />
								</div>
								<div class="h1 ml-5" v-else-if="ai === 1">
									<img :src="baseUrl + '/media/smileys/frowning_face.gif'" />
								</div>
								<div class="h1 ml-5" v-else-if="ai === 2">
									<img :src="baseUrl + '/media/smileys/smiling_face.gif'" />
								</div>
								<div class="h1 ml-5" v-else-if="ai === 3">
									<img :src="baseUrl + '/media/smileys/smiling_face_with_heart_eyes.gif'" />
								</div>
							</div>
						</div>
						<div :class="(question.option_2.point_system_active === true) ? 'col-4' : 'col-6'" v-if="question.type !== 'checkbox'">
							<div class="form-group">
								<treeselect ref="answer" :multiple="false" v-model="answer.follow" :options="followOptionsCalced" :normalizer="normalizer" :disable-branch-nodes="true" :placeholder="$t('VIEWS.Survey.Create.Question.chooseFollow')" />
							</div>
						</div>
						<div class="col-2" v-if="question.option_2.point_system_active && question.option_2.point_system_active === true">
							<div class="form-group">
								<b-form-input type="number" v-model="answer.points"></b-form-input>
							</div>
						</div>
						<div class="col-1 pr-0 answer-trash" v-if="ai > 1 && question.type !== 'smileys'">
							<a class="d-inline-block mt-3" href="" v-on:click.prevent="deleteAnswer(answer)">
								<i class="my-auto mx-auto fas fa-trash-alt"></i>
							</a>
						</div>
					</div>
				</template>
			</draggable>
		</div>
	</div>
</template>

<script>
import Answer from '@/data/survey/type/Answer';
import { v4 as uuidv4 } from 'uuid';
import TranslationAnswer from "@/data/survey/translation/Answer";
import {klona} from "klona";

export default {
	name: 'SurveyOption',
	props: {
		question: Object,
		index: Number,
		followOptions: Array,
	},
	data: function() {
		return {
			previousType: '',
		};
	},
	computed: {
		baseUrl() {
			return process.env.VUE_APP_URL;
		},
		followOptionsCalced() {
			let options = klona(this.followOptions);
			let deactivate = true;

			options.forEach(option => {
				option.children.forEach(q => {
					if (q.id !== this.question.id && deactivate === true) {
						q.isDisabled = true;
					} else if (q.id === this.question.id) {
						q.isDisabled = true;
						deactivate = false;
					}
				});
			});

			return options;
		},
	},
	mounted() {
		this.previousType = this.question.type;
	},
	methods: {
		normalizer: function(node) {
			return {
				id: node.id,
				label: node.label,
				children: node.children,
			};
		},
		deleteAnswer: function(answer) {
			this.question.answers = this.question.answers.filter(function(value) {
				return value.id !== answer.id;
			});
		},
		addAnswerInput: function(ev, index) {
			if (ev.inputType === 'insertText') {
				this.addAnswer(index);
			}
		},
		addAnswer: function(index) {
			if (this.question.answers[index].text.length > 0 && this.question.answers.length - 1 === index) {
				let answer = new Answer(uuidv4());
				this.question.translation.forEach(translation => {
					let translationAnswer = new TranslationAnswer();
					translationAnswer.code = translation.code;
					answer.translation.push(translationAnswer);
				});
				this.question.answers.push(answer);
			}
		},
		changeType: function(checked) {
			if (checked === 'smileys') {
				this.question.answers = [];
				this.question.answers.push(new Answer(uuidv4()));
				this.question.answers.push(new Answer(uuidv4()));
				this.question.answers.push(new Answer(uuidv4()));
				this.question.answers.push(new Answer(uuidv4()));

				this.question.answers[0].text;
			}
		},
	},
	watch: {
		question: {
			deep: true,
			handler: function(newQuestion) {
				if (this.previousType !== newQuestion.type) {
					if (newQuestion.type === 'smileys') {
						newQuestion.answers = [];
						newQuestion.answers.push(new Answer(uuidv4()));
						newQuestion.answers.push(new Answer(uuidv4()));
						newQuestion.answers.push(new Answer(uuidv4()));
						newQuestion.answers.push(new Answer(uuidv4()));

						newQuestion.answers[0].text;
					}
				}

				this.previousType = newQuestion.type;
			}
		}
	}
};
</script>

<style scoped>
div.answer-trash > a > i {
	font-size: 1.5rem;
	color: #2f323e;
}
div.smileys img {
	width: 3rem;
}
</style>
