<template>
	<div v-frag>
		<tr>
			<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
				<div class="ml-3">
					<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.name')">
						<b-input-group>
							<template #prepend>
								<b-input-group-text
								><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.topic') }}</strong></b-input-group-text
								>
							</template>
							<b-form-input type="text" disabled :value="topic.name"></b-form-input>
						</b-input-group>
					</b-form-group>
				</div>
			</th>
			<td v-for="(translation, tii) in topic.translation" :key="'topic-' + index + '-name-' + tii" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
				<div class="ml-3">
					<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.name')">
						<b-input-group>
							<template #prepend>
								<b-input-group-text
								><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.topic') }}</strong></b-input-group-text
								>
							</template>
							<b-form-input type="text" v-model="topic.translation[tii].name"></b-form-input>
						</b-input-group>
					</b-form-group>
				</div>
			</td>
		</tr>
		<tr>
			<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
				<div class="ml-3">
					<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.title')">
						<b-input-group>
							<template #prepend>
								<b-input-group-text
								><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.topic') }}</strong></b-input-group-text
								>
							</template>
							<b-form-input type="text" disabled :value="topic.title"></b-form-input>
						</b-input-group>
					</b-form-group>
				</div>
			</th>
			<td v-for="(translation, tii) in topic.translation" :key="'topic-' + index + '-title-' + tii" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
				<div class="ml-3">
					<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.title')">
						<b-input-group>
							<template #prepend>
								<b-input-group-text
								><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.topic') }}</strong></b-input-group-text
								>
							</template>
							<b-form-input type="text" v-model="topic.translation[tii].title"></b-form-input>
						</b-input-group>
					</b-form-group>
				</div>
			</td>
		</tr>
		<tr>
			<th class="first-col" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
				<div class="ml-3">
					<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.description')">
						<b-input-group>
							<template #prepend>
								<b-input-group-text
								><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.topic') }}</strong></b-input-group-text
								>
							</template>
							<b-form-textarea readonly rows="3" no-resize :value="topic.description"></b-form-textarea>
						</b-input-group>
					</b-form-group>
				</div>
			</th>
			<td v-for="(translation, tii) in topic.translation" :key="'topic-' + index + '-description-' + tii" :style="{ width: 'calc(' + columnWidth + ' - 2rem)'}">
				<div class="ml-3">
					<b-form-group class="no-form-margin" :description="$t('VIEWS.Survey.Create.General.description')">
						<b-input-group>
							<template #prepend>
								<b-input-group-text
								><strong>{{ $t('VIEWS.Survey.Create.Language.Abbreviations.topic') }}</strong></b-input-group-text
								>
							</template>
							<b-form-textarea rows="3" no-resize v-model="topic.translation[tii].description"></b-form-textarea>
						</b-input-group>
					</b-form-group>
				</div>
			</td>
		</tr>
		<template v-for="(question, qi) in topic.questions">
			<QuestionLanguage v-if="question.type !== 'matrix'" :question="question" :index="qi" :ti="index" :key="'topic-' + index + '-question-' + qi"></QuestionLanguage>
			<MatrixLanguage v-if="question.type === 'matrix'" :question="question" :key="'topic-' + index + '-question-' + qi"></MatrixLanguage>
		</template>
	</div>
</template>

<script>
import QuestionLanguage from "@/view/component/survey/language/QuestionLanguage";
import MatrixLanguage from "@/view/component/survey/language/MatrixLanguage";
export default {
	name: 'TopicLanguage',
	components: {MatrixLanguage, QuestionLanguage},
	props: {
		topic: Object,
		index: Number,
	},
	data: function() {
		return {
			columnWidth: '400px',
		}
	},
	mounted() {
		let contentDiv = document.getElementById('kt_content');
		if (contentDiv != null) {
			let width = contentDiv.clientWidth;
			this.columnWidth =  width / 3 + 'px';
		}
	}
};
</script>

<style scoped></style>
