import General from "@/data/survey/type/General";

class Matrix extends General {
	constructor(id) {
		super(id);
		this.type = 'matrix';
		this.is_regression = 0;
		this.is_regression_target = 0;
		this.options = [];
		this.questions = [];
		this.is_required = false;
		this.personality_active = false;
	}
}

export default Matrix;
