class TranslationSurvey {
	constructor() {
		this.code = null;
		this.name = '';
		this.title = '';
		this.sub_title = '';
		this.description = '';
		this.start_text = '';
		this.next_text = '';
		this.previous_text = '';
		this.others_text = '';
		this.no_answer_text = '';
	}
}

export default TranslationSurvey;
