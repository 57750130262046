import General from "@/data/survey/type/General";

class Option extends General {
	constructor(id) {
		super(id);
		this.type = 'radio';
		this.option_0 = 0;
		this.option_1 = 0;
		this.option_2 = {
			point_system_active: false,
		};
		this.answers = [];
		this.is_regression = 0;
		this.is_regression_target = 0;
		this.is_required = false;
		this.personality_active = false;
	}
}

export default Option;
