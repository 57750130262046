<template>
	<div>
		<div class="form-group">
			<label>{{ $t('VIEWS.Survey.Create.Question.chooseFollow') }}</label>
			<treeselect ref="answer" :multiple="false" v-model="question.option_0" :options="followOptionsCalced" :normalizer="normalizer" :disable-branch-nodes="true" :placeholder="$t('VIEWS.Survey.Create.Question.chooseFollow')" />
		</div>
		<div class="form-group">
			<label v-if="question.type === 'information'">{{ $t('VIEWS.Survey.Create.Question.Text.labelInformation') }}</label>
			<label v-else>{{ $t('GENERAL.Forms.description') }}</label>
			<textarea class="form-control" rows="5" name="description" v-model="question.description"></textarea>
		</div>

		<div class="">
			<div class="col-12 row container">
				<div class="col-4 pl-0"><h5 class="ml-2">{{ $t('VIEWS.Survey.Create.Question.Contact.fields') }}</h5></div>
				<div class="col-3 pl-0"><h5 class="ml-2">{{ $t('VIEWS.Survey.Create.Question.Contact.type.types') }}</h5></div>
				<div class="col-3 pl-0"><h5 class="ml-2">{{ $t('VIEWS.Survey.Create.Question.Contact.type.association') }}</h5></div>
				<div class="col-1 text-center"><i class="fas fa-exclamation text-dark mr-2"></i></div>
				<div class="col-1 pr-0"></div>
			</div>

			<template v-for="(field, fi) in question.option_2">
				<div class="col-12 row container" :key="'c1' + index + '-' + fi">
					<div class="col-4 pl-0">
						<div class="form-group">
							<input class="form-control" type="text" name="text" v-model="field.name" :placeholder="$t('VIEWS.Survey.Create.Question.Contact.fieldPlaceholder') + ' ' + (fi + 1)" v-on:input="addFieldInput($event, fi)" v-on:focusin="addField(fi)" />
						</div>
					</div>
					<div class="col-3 pl-0">
						<div class="form-group">
							<b-select v-model="field.type">
								<b-select-option value="text">{{ $t('VIEWS.Survey.Create.Question.Contact.type.text') }}</b-select-option>
								<b-select-option value="mail">{{ $t('VIEWS.Survey.Create.Question.Contact.type.mail') }}</b-select-option>
								<b-select-option value="number">{{ $t('VIEWS.Survey.Create.Question.Contact.type.number') }}</b-select-option>
								<b-select-option value="datetime">{{ $t('VIEWS.Survey.Create.Question.Contact.type.datetime') }}</b-select-option>
								<b-select-option value="date">{{ $t('VIEWS.Survey.Create.Question.Contact.type.date') }}</b-select-option>
								<b-select-option value="time">{{ $t('VIEWS.Survey.Create.Question.Contact.type.time') }}</b-select-option>
							</b-select>
						</div>
					</div>
					<div class="col-3 pl-0">
						<div class="form-group">
							<b-select v-model="field.association">
								<b-select-option value="">{{ $t('GENERAL.General.none') }}</b-select-option>
								<b-select-option value="firstname">{{ $t('GENERAL.General.person.firstName') }}</b-select-option>
								<b-select-option value="lastname">{{ $t('GENERAL.General.person.lastName') }}</b-select-option>
								<b-select-option value="phone">{{ $t('GENERAL.General.person.phone') }}</b-select-option>
								<b-select-option value="email">{{ $t('GENERAL.General.person.email') }}</b-select-option>
							</b-select>
						</div>
					</div>
					<div class="col-1 text-center">
						<b-form-checkbox v-b-tooltip.hover.right :title="$t('VIEWS.Survey.Create.Question.Contact.required')" class="mt-2" size="lg" :true-value="true" :false-value="false" v-model="field.required"></b-form-checkbox>
					</div>
					<div class="col-1 pr-0 answer-trash text-center" v-if="fi > 0">
						<a class="d-inline-block mt-3" href="" v-on:click.prevent="deleteField(fi)">
							<i class="my-auto mx-auto fas fa-trash-alt"></i>
						</a>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { klona } from 'klona';
import { ContactField } from '@/data/survey/type/Contact';
import { v4 as uuidv4 } from 'uuid';
import TranslationAnswer from "@/data/survey/translation/Answer";

export default {
	name: 'SurveyContact',
	props: {
		question: Object,
		index: Number,
		followOptions: Array,
	},
	computed: {
		followOptionsCalced() {
			let options = klona(this.followOptions);
			let deactivate = true;

			options.forEach(option => {
				option.children.forEach(q => {
					if (q.id !== this.question.id && deactivate === true) {
						q.isDisabled = true;
					} else if (q.id === this.question.id) {
						q.isDisabled = true;
						deactivate = false;
					}
				});
			});

			return options;
		},
	},
	methods: {
		normalizer: function(node) {
			return {
				id: node.id,
				label: node.label,
				children: node.children,
			};
		},
		deleteField: function(index) {
			this.question.option_2.splice(index, 1);
		},
		addFieldInput: function(ev, index) {
			if (ev.inputType === 'insertText') {
				this.addField(index);
			}
		},
		addField: function(index) {
			if (this.question.option_2[index].name.length > 0 && this.question.option_2.length - 1 === index) {
				let field = new ContactField(uuidv4(), '', 'text', false);
				this.question.translation.forEach(translation => {
					let translationAnswer = new TranslationAnswer();
					translationAnswer.code = translation.code;
					field.translation.push(translationAnswer);
				});
				this.question.option_2.push(field);
			}
		},
	},
};
</script>

<style scoped>
div.answer-trash > a > i {
	font-size: 1.5rem;
	color: #2f323e;
}
</style>
