<template>
	<div>
		<h5 class="mb-4">{{ $t('VIEWS.Survey.Create.Question.Nps.label') }}</h5>
		<div class="row font-size-h6 text-dark-75 mb-4 pl-4">
			<div class="col-3 align-self-center">{{ $t('VIEWS.Survey.Create.Question.Nps.from') }}</div>
			<div class="col-3 align-self-center">{{ $t('VIEWS.Survey.Create.Question.Nps.to') }}</div>
			<div class="col-5 align-self-center">{{ $t('VIEWS.Survey.Create.Question.Nps.follow') }}</div>
			<div class="col-1 d-flex">
				<b-button @click="addCondition" variant="outline-primary" size="sm" class="mx-auto">+</b-button>
			</div>
		</div>
		<div class="row mb-2 align-items-center" v-for="(input, index) in question.condition" :key="'c' + question.id + index">
			<div class="col-3">
				<input class="form-control" type="number" min="0" max="10" name="from[]" v-model="input.from" />
			</div>
			<div class="col-3">
				<input class="form-control" type="number" min="0" max="10" name="to[]" v-model="input.to" />
			</div>
			<div class="col-5">
				<treeselect :multiple="false" v-model="input.follow" :options="followOptionsCalced" :normalizer="normalizer" :disable-branch-nodes="true" :placeholder="$t('VIEWS.Survey.Create.Question.chooseFollow')" />
			</div>
			<div class="col-1 answer-trash">
				<a href="" class="px-2 d-flex" v-on:click.prevent="deleteCondition(input.from)">
					<i class="my-auto mx-auto fas fa-trash-alt"></i>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import {klona} from "klona";

export default {
	name: 'SurveyNps',
	props: {
		question: Object,
		index: Number,
		followOptions: Array,
	},
	data() {
		return {
			options: [
				{
					text: this.$t('VIEWS.Survey.Create.Question.Text.text'),
					value: 'text',
				},
				{
					text: this.$t('VIEWS.Survey.Create.Question.Text.information'),
					value: 'information',
				},
			],
		};
	},
	computed: {
		followOptionsCalced() {
			let options = klona(this.followOptions);
			let deactivate = true;

			options.forEach(option => {
				option.children.forEach(q => {
					if (q.id !== this.question.id && deactivate === true) {
						q.isDisabled = true;
					} else if (q.id === this.question.id) {
						q.isDisabled = true;
						deactivate = false;
					}
				});
			});

			return options;
		},
	},
	methods: {
		normalizer: function(node) {
			return {
				id: node.id,
				label: node.label,
				children: node.children,
			};
		},
		deleteCondition(from) {
			this.question.condition = this.question.condition.filter(function(value){ return value.from !== from; });
		},
		addCondition() {
			let nextFrom = 0;
			this.question.condition.forEach((nps) => {
				if (nextFrom < nps.to) {
					nextFrom = nps.to;
				}
			});

			nextFrom = parseInt(nextFrom);

			if ((nextFrom + 2) <= 10) {
				this.question.condition.push({
					from: nextFrom + 1,
					to: nextFrom + 2,
					follow: null,
				});
			}
		}
	},
};
</script>

<style scoped>
div.answer-trash > a > i {
	font-size: 1.5rem;
	color: #2f323e;
}
</style>
